import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  flex-shrink: 0;

  .microphone-new-active {
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00a1ff;
    cursor: pointer;
  }

  .microphone-new-active img {
    width: 50px;
    cursor: pointer;
  }

  .microphone-new-active::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    border: 1px solid #0dd70e;
    border-radius: 50%;
    animation: line-pulse-effects 1.5s linear infinite;
  }

  .microphone-new-active::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    border: 1px solid #0dd70e;
    border-radius: 50%;
    animation: line-pulse-effects 1.5s linear infinite;
    animation-delay: 0.4s;
  }

  @keyframes line-pulse-effects {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(1.4);
      opacity: 0;
    }
  }
`;
export default Container;
