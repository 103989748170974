import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  background-color: white;
  max-width: 420px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
export default Container;
