import React, { useRef, useState } from "react";
import { setFullScreen, toggleMic } from "../../Store/Dispatcher/behaviour";
import "./styles.css";
import { getOtp, verifyOtp } from "../../Api/login";
import { useTimer } from "react-timer-hook";
import moment from "moment";

const mic = require("../../Assets/mic.svg").default;
const sendbtn = require("../../Assets/send.png");

const Phone = () => {
  const [key, setKey] = useState("");
  const ref = useRef<HTMLInputElement>(null);
  const [phone, setPhone] = useState("");
  const [isOTP, setIsOTP] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp: time,
    onExpire: () => console.warn("onExpire called"),
  });

  const handleSaveLocal = async () => {
    setError("");
    if (!ref.current.value) return;
    setLoading(true);
    const results = await verifyOtp(ref.current.value);
    setLoading(false);
    if (results.error) {
      setError(results.message);
      ref.current.value = "";
    } else {
      localStorage.setItem("phoneNumber", phone);
      setFullScreen({
        type: null,
        component: null,
      });
    }
  };

  const handleNumberSave = async () => {
    setError("");
    if (ref.current.value) {
      setPhone(ref.current.value);
      setLoading(true);
      localStorage.setItem("phoneNumber", ref.current.value);
      const results = await getOtp(ref.current.value);
      setLoading(false);
      if (results.error) {
        setError(results.message);
      } else {
        setIsOTP(true);
      }
    }
  };

  const resendOTP = async () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 120);
    restart(time);
    let number = localStorage.getItem("phoneNumber");

    setError("");
    if (number) {
      setPhone(number);
      setLoading(true);
      const results = await getOtp(number);
      setLoading(false);
      if (results.error) {
        setError(results.message);
      } else {
        setIsOTP(true);
      }
    }
  };

  return (
    <div
      style={{
        flexGrow: 1,

        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      id="corover-body"
    >
      {!isOTP ? (
        <div
          style={{
            //   position: "absolute",
            //   top: "50%",
            //   left: "50%",
            //   transform: "translate(-50%, -50%)",
            width: "90%",
            margin: "0 auto",
            marginTop: "20%",
          }}
        >
          <h4
            style={{
              fontSize: "18px",
              color: "#2e2e2e",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
          >
            Please provide your phone number
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",

              border: "1px solid gray",
              padding: "10px",
              borderRadius: "10px",
              margin: "0 auto",
            }}
          >
            <span
              style={{
                fontSize: "24px",
                fontWeight: "700",
                color: "rgb(91, 91, 91)",
                display: "inline-block",
                marginRight: "10px",
              }}
            >
              +91
            </span>
            <input
              type="text"
              style={{
                width: "100%",
                fontSize: "24px",
                fontWeight: "600",
                color: "rgb(91, 91, 91)",
                border: "none",
                outline: "none",
              }}
              ref={ref}
              className="phone-input"
              placeholder="Provide your phone number"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleNumberSave();
                }
              }}
            />
            {/* <div
              className="mic"
              onClick={() => {
                toggleMic();
              }}
              style={{ marginLeft: "10px", cursor: "pointer" }}
            >
              <img
                style={{
                  marginLeft: "0px",

                  width: "18px",
                }}
                src={key === "" ? mic : sendbtn}
                alt=""
              />
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              marginTop: "6px",
              color: "tomato",
            }}
          >
            {error}
          </p>
          <button
            style={{
              margin: "0 auto",
              display: "block",
              marginTop: "20px",
              padding: "10px",
              border: "none",
              background: loading ? "gainsboro" : "#f79e01",
              color: "white",
              fontSize: "18px",
              width: "100%",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            disabled={loading}
            onClick={() => handleNumberSave()}
          >
            Submit
          </button>
        </div>
      ) : (
        <div
          style={{
            //   position: "absolute",
            //   top: "50%",
            //   left: "50%",
            //   transform: "translate(-50%, -50%)",
            width: "90%",
            margin: "0 auto",
            marginTop: "20%",
          }}
        >
          <h4
            style={{
              fontSize: "18px",
              color: "#2e2e2e",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
          >
            Please provide the 6 digit OTP sent to your phone number
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",

              border: "1px solid gray",
              padding: "10px",
              borderRadius: "10px",
              margin: "0 auto",
            }}
          >
            <input
              type="text"
              style={{
                width: "100%",
                fontSize: "24px",
                fontWeight: "600",
                color: "rgb(91, 91, 91)",
                border: "none",
                outline: "none",
                letterSpacing: "22px",
                textAlign: "center",
              }}
              ref={ref}
              maxLength={6}
              className="phone-input"
              placeholder="******"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSaveLocal();
                }
              }}
            />
            {/* <div
              className="mic"
              onClick={() => {
                toggleMic();
              }}
              style={{ marginLeft: "10px", cursor: "pointer" }}
            >
              <img
                style={{
                  marginLeft: "0px",

                  width: "18px",
                }}
                src={key === "" ? mic : sendbtn}
                alt=""
              />
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              marginTop: "6px",
              color: "tomato",
            }}
          >
            {error}
          </p>
          <button
            style={{
              margin: "0 auto",
              display: "block",
              marginTop: "20px",
              padding: "10px",
              border: "none",
              background: loading ? "gainsboro" : "#f79e01",
              color: "white",
              fontSize: "18px",
              width: "100%",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            disabled={loading}
            onClick={() => handleSaveLocal()}
          >
            SEND
          </button>
          {isRunning ? (
            <p style={{ textAlign: "center", marginTop: "16px" }}>
              Resend OTP in{" "}
              {moment(`${minutes}:${seconds}`, "m:s").format("mm:ss")}
            </p>
          ) : (
            <button
              style={{
                display: "block",
                margin: "16px auto 0px",
                background: "transparent",
                border: "none",
                color: "#f79e01",
                fontSize: "16px",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={resendOTP}
            >
              Resend Otp
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Phone;
