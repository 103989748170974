import moment from "moment";

const getPosition = (currentPosition, code) => {
  const s = currentPosition.split("(")[1]?.split(")")[0];

  if (s === code) return 1;
  else return 0;
};

const constructStation = (station) => {
  return {
    code: station.station,
    name: station.stationName,
    distance: station.distanceFromDest,
    pfOrHalt: station.pf ? `PF ${station.pf}` : "Halt",
    delay:
      station.delayDeparture === "On Time" || station.delayDeparture === ""
        ? "On Time"
        : moment(station.delayDeparture, "HH:mm").format("HH:mm"),
    position: getPosition(station.currentPosition, station.station),
    scheduledArrival: station.sta
      ? moment(station.sta, "DD-MMM-YYYY HH:mm").format("HH:mm")
      : "--:--",
    scheduledDeparture: station.std
      ? moment(station.std, "DD-MMM-YYYY HH:mm").format("HH:mm")
      : "--:--",
    actualArrival: station.eta
      ? moment(station.eta, "DD-MMM-YYYY HH:mm").format("HH:mm")
      : "--:--",
    actualDeparture: station.etd
      ? moment(station.etd, "DD-MMM-YYYY HH:mm").format("HH:mm")
      : "--:--",
  };
};

const getDetails = (data) => {
  let d = {
    trainNumber: data.trainNo,
    trainName: data.trainName,
    source: data.source,
    destination: data.destination,
    sourceLabel: data.sourceName,
    destinationLabel: data.destinationName,
    stations: data.schedule.map((station) => constructStation(station)),
  };

  return d;
};

export default getDetails;
