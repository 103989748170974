import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  .first-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid grey;
    padding: 8px 12px;
    border-radius: 18px;

    img {
      width: 22px;
      margin-right: 10px;
    }

    .mic {
      width: 16px;
      cursor: pointer;
      margin: 0;
    }

    input {
      width: 100%;
      font-size: 16px;
      outline: none;
      border: none;
    }
  }

  button {
    font-size: 16px;
    color: black;
    font-weight: 500;
    background-color: transparent;
    border: none;
    margin-left: 15px;
    cursor: pointer;
  }

  p {
    font-size: 16px;
    color: black;
    font-weight: 400;
  }
`;

export default Container;
