import store from "..";

import {
  HIDE_ALSO_TRY,
  PLAY_AUDIO,
  SET_APP_LANGUAGE,
  SET_CURRENT,
  SET_CXTOKEN,
  SET_DEVICE_ID,
  SET_FALLBACK,
  SET_IS_REFUND,
  SET_NEXT_CONTEXT,
  SET_PREV_CODE,
  SET_QUERY_LANGUAGE,
  SET_SESSION_ID,
  SET_STATUS,
  SET_USER_TOKEN,
  SHOW_ALSO_TRY,
  STOP_AUDIO,
  TOGGLE_MESSAGE_LOADER,
} from "./constants";

import { ReactChild } from "react";

export const toggleMessageLoader = () => {
  const action = { type: "TOGGLE_MESSAGE_LOADER" };
  return action;
};

export const toggleMic = () => {
  const action = { type: "TOGGLE_MIC" };
  return action;
};

export const setLanguage = (lang) => {
  const action = {
    type: "SET_LANGUAGE",
    lang: lang,
  };
  return action;
};

export const setFullScreenComponent = ({
  type,
  component,
}: {
  type: string;
  component: ReactChild;
}) => {
  const action = {
    type: "SET_FULLSCREEN_COMPONENT",
    value: { type: type, component: component },
  };
  return action;
};

export const setCurrentStep = (value) => {
  const action = { type: "SET_CURRENT_STEP", value: value };
  return action;
};

export const setInputType = (value) => {
  const action = { type: "SET_INPUT_TYPE", value: value };
  return action;
};

export const setOptions = (value) => {
  const action = { type: "SET_OPTIONS", value: value };
  return action;
};

export const playMessageAudio = ({ id, url }: { id: string; url: string }) => {
  const action = {
    type: PLAY_AUDIO,
    id: id,
    url: url,
  };
  return action;
};

export const stopMessageAudio = () => {
  const action = {
    type: STOP_AUDIO,
  };
  return action;
};

export const setUserToken = (value) => {
  const action = { type: "SET_USER_TOKEN", value: value };
  return action;
};

// export const setCxToken = (value) => {
//   const action = { type: SET_CXTOKEN, value: value };
//   return action;
// };

export const setNextContext = (value) => {
  const action = { type: SET_NEXT_CONTEXT, value: value };
  return action;
};

// export const setSessionId = (value) => {
//   const action = { type: SET_SESSION_ID, value: value };
//   return action;
// };

// export const setDeviceId = (value) => {
//   const action = { type: SET_DEVICE_ID, value: value };
//   return action;
// };

// export const setPrevCode = (value) => {
//   const action = { type: SET_PREV_CODE, value: value };
//   return action;
// };

// export const setStatus = (value) => {
//   const action = { type: SET_STATUS, value: value };
//   return action;
// };

// export const showLogin = () => {
//   const action = { type: SHOW_LOGIN };
//   return action;
// };

// export const showTrains = () => {
//   const action = { type: SHOW_TRAINS };
//   return action;
// };

// export const hideTemplate = () => {
//   const action = { type: HIDE_TEMPLATE };
//   return action;
// };

// export const setIsFallback = (value) => {
//   const action = { type: SET_FALLBACK, value: value };
//   return action;
// };

// export const setIsRefund = (value) => {
//   const action = { type: SET_IS_REFUND, value: value };
//   return action;
// };

// export const setRecentJourneys = (value) => {
//   const action = { type: "SET_RECENT_JOURNEYS", value: value };
//   return action;
// };

// export const setIrctcDetails = (value) => {
//   const action = { type: "SET_IRCTC_DETAILS", value: value };
//   return action;
// };

// export const setLocalData = (value) => {
//   const action = { type: "SET_LOCAL_DATA", value: value };
//   return action;
// };

// export const setUnAuth = () => {
//   const action = { type: "SET_UNAUTH" };
//   return action;
// };

export const setAll = (value) => {
  const action = { type: "SET_ALL_STATIONS", value: value };
  return action;
};

export const setPopular = (value) => {
  const action = { type: "SET_POPULAR_STATIONS", value: value };
  return action;
};

export const setAllTrains = (value) => {
  const action = { type: "SET_ALL_TRAINS", value: value };
  return action;
};

export const setBlink = (value) => {
  const action = { type: "SET_BLINK", value: value };
  return action;
};

export const setAudioURL = (value) => {
  const action = { type: "SET_AUDIOURL", value: value };
  return action;
};

export const togglePending = () => {
  const action = { type: "TOGGLE_ISAUDIOPENDING" };
  return action;
};
