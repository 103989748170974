import React, { useEffect, useRef, useState } from "react";
import SelectPicker from "../../Chatbot/Body/Sender/SelectBox/SelectPicker";
import useGetReduxState from "../../Hooks/useGetReduxState";
import useSendQuery from "../../Hooks/useSendQuery";
import { resetComplaintData } from "../../Store/Dispatcher/complaints";
import Drawer from "../../UI/Drawer";
import Select from "./Select";

const langText = {
  en: {
    header: "Confirmation",
    footer: "Confirm",
    subHeading:
      "Please confirm the following details based on your complaint:-",
    related: "Complaint Related to:",
    train: "Train",
    station: "Station",
    complaintType: "Complaint Type",
    subComplaintType: "Sub-Complaint Type",
    pnr: "PNR:",
    trainNo: "Train Number:",
    placeholderPnr: "Provide your PNR number...",
    placeholderTrain: "Provide your train number...",
    placeholderType: "Choose complaint type...",
    placeholderSubType: "Choose sub-complaint type...",
    errPNR: "Please provide a valid PNR number",
    errTrain: "Please provide a valid train number",
  },
  hi: {
    header: "पुष्टीकरण",
    footer: "पुष्टि करें",
    subHeading:
      "कृपया अपनी शिकायत के आधार पर निम्नलिखित विवरणों की पुष्टि करें:-",
    related: "शिकायत संबंधित:",
    train: "ट्रैन",
    station: "स्टेशन",
    complaintType: "शिकायत का प्रकार",
    subComplaintType: "शिकायत का उप प्रकार",
    pnr: "PNR:",
    trainNo: "ट्रैन नंबर:",
    placeholderPnr: "कृपया अपना PNR नंबर दर्ज करें...",
    placeholderTrain: "कृपया अपना ट्रैन नंबर दर्ज करें...",
    placeholderType: "शिकायत का प्रकार चुनें...",
    placeholderSubType: "शिकायत का उप प्रकार चुनें...",
    errPNR: "कृपया मान्य PNR नंबर दर्ज करें",
    errTrain: "कृपया एक मान्य ट्रेन नंबर दर्ज करें",
  },
};

const Header = () => {
  const lang = useGetReduxState().behaviour.lang;
  return <div>{langText[lang].header}</div>;
};

const Footer = ({ handleSubmit }) => {
  const lang = useGetReduxState().behaviour.lang;
  return (
    <button
      style={{
        width: "100%",
        fontSize: "18px",
        padding: "10px",
        border: "none",
        color: "white",
        background: "#f79e01",
        borderRadius: "7px",
        cursor: "pointer",
      }}
      onClick={handleSubmit}
    >
      {langText[lang].footer}
    </button>
  );
};

const Content = ({ compType, subType, handleRelated, handleOpen }) => {
  const lang: "hi" | "en" = useGetReduxState().behaviour.lang;
  const comp = useGetReduxState().complaint.parseData;

  return (
    <div style={{ padding: "10px 16px" }}>
      {/* <SelectPicker header={"Please Select Complaint Type"} options={[{label:"Medical Allowance", value:"1"},{label:"Medical Allowance", value:"1"},{label:"Medical Allowance", value:"1"}]} handleClose={undefined} handleClick={()=>{}}/> */}
      <span
        style={{
          fontSize: "14px",
          lineHeight: "1.2",
          display: "block",
          marginBottom: "16px",
        }}
      >
        {langText[lang].subHeading}
      </span>

      {/* <div style={{ marginTop: "10px" }}>
        <p style={{ fontSize: "12px", fontWeight: "500", color: "#505963" }}>
          {langText[lang].related}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            marginTop: "5px",
          }}
        >
          <p
            style={{
              border: "1px solid black",
              fontSize: "16px",
              padding: "7px 10px",
              width: "49%",
              textAlign: "center",
              borderRadius: "6px",
              background: relType.key === "s" ? "black" : "white",
              color: relType.key === "s" ? "white" : "black",
              cursor: "pointer",
            }}
            onClick={() => handleRelated({ label: "Station", key: "s" })}
          >
            {langText[lang].station}
          </p>
          <p
            style={{
              border: "1px solid black",
              fontSize: "16px",
              padding: "7px 10px",
              width: "49%",
              textAlign: "center",
              borderRadius: "6px",
              background: relType.key !== "s" ? "black" : "white",
              color: relType.key !== "s" ? "white" : "black",
              cursor: "pointer",
            }}
            onClick={() => handleRelated({ label: "Train", key: "t" })}
          >
            {langText[lang].train}
          </p>
        </div>
      </div> */}

      {/* {relType.key === "t" && (
        <>
          {" "}
          <div>
            <p
              style={{ fontSize: "12px", fontWeight: "500", color: "#505963" }}
            >
              {langText[lang].pnr}
            </p>
            <input
              style={{
                fontSize: "16px",
                padding: "7px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid gray",
                marginTop: "5px",
                outline: "none",
              }}
              ref={pnrRef}
              type="number"
              placeholder={langText[lang].placeholderPnr}
            />
          </div>
          <div style={{ margin: "10px 0px" }}>
            <p
              style={{ fontSize: "12px", fontWeight: "500", color: "#505963" }}
            >
              {langText[lang].trainNo}
            </p>
            <input
              style={{
                fontSize: "16px",
                padding: "7px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid gray",
                marginTop: "5px",
                outline: "none",
              }}
              ref={trainNumRef}
              type="number"
              placeholder={langText[lang].placeholderTrain}
            />
          </div>
        </>
      )} */}

      <div
        onClick={() => {
          handleOpen("types");
        }}
      >
        <p style={{ fontSize: "12px", fontWeight: "500", color: "#505963" }}>
          {langText[lang].complaintType}
        </p>
        <p
          style={{
            width: "100%",
            border: "1px solid gray",
            borderRadius: "6px",
            fontSize: "18px",
            padding: "6px 10px",
            marginTop: "5px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: compType.label ? "black" : "#949494",
          }}
        >
          {compType.label ? compType.label : langText[lang].placeholderType}{" "}
          <span style={{ fontSize: "12px", color: "black" }}>▼</span>
        </p>
      </div>
      <div
        onClick={() => {
          handleOpen("subtypes");
        }}
        style={{ marginTop: "10px", marginBottom: "12px" }}
      >
        <p style={{ fontSize: "12px", fontWeight: "500", color: "#505963" }}>
          {langText[lang].subComplaintType}
        </p>
        <p
          style={{
            width: "100%",
            border: "1px solid gray",
            borderRadius: "6px",
            fontSize: "18px",
            padding: "6px 10px",
            marginTop: "5px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: subType.label ? "black" : "#949494",
          }}
        >
          {subType.label ? subType.label : langText[lang].placeholderSubType}{" "}
          <span style={{ fontSize: "12px", color: "black" }}>▼</span>
        </p>
      </div>
      {/* {Err && <p style={{ color: "tomato" }}>{Err}</p>} */}
    </div>
  );
};

const ConfirmDrawer = () => {
  const complainData = useGetReduxState().complaint;

  const [compType, setCompType] = useState(
    complainData.isParsed
      ? complainData.parseData.complaintType
      : {
          label: "",
          key: "",
        }
  );
  const [subType, setSubType] = useState(
    complainData.isParsed
      ? complainData.parseData.subType
      : {
          label: "",
          key: "",
        }
  );

  // const [relType, setRelatedType] = useState(
  //   complainData.isParsed
  //     ? complainData.parseData.related
  //     : {
  //         label: "",
  //         key: "s",
  //       }
  // );
  const [open, setOpen] = useState("");

  const pnrRef = useRef<HTMLInputElement>();
  const trainRef = useRef<HTMLInputElement>();

  const { sendQueryPayload } = useSendQuery();

  const handleComplaintSelect = (value) => {
    setCompType(value);

    setOpen("");
  };

  const handleSubTypeSelect = (value) => {
    setSubType(value);

    setOpen("");
  };

  useEffect(() => {
    if (compType.key !== complainData.parseData.complaintType.key)
      setSubType({
        label: "",
        key: "",
      });
  }, [compType]);

  const lang: "hi" | "en" = useGetReduxState().behaviour.lang;

  const handleSubmit = () => {
    if (compType.key && subType.key) {
      sendQueryPayload({
        query: lang === "en" ? "Details submitted" : "विवरण दर्ज किया गया",
        inputType: "",
        data: null,
        cxpayload: {
          type: compType.key,
          subType: subType.key,
          description: complainData.parseData.description,
        },
      });
    }
  };

  const [Err, setErr] = useState("");

  return (
    <>
      <Drawer
        header={<Header />}
        content={
          <Content
            compType={compType}
            subType={subType}
            handleRelated={(rel) => {}}
            handleOpen={(val) => setOpen(val)}
          />
        }
        footer={<Footer handleSubmit={handleSubmit} />}
        handleCloseDrawer={() => {}}
        showCross={false}
        fullHeight={false}
        showHeaderSeperator={true}
      />
      {open === "types" && (
        <Select
          type={"types"}
          options={complainData.allTypes}
          handleChoose={(value) => handleComplaintSelect(value)}
          handleClose={() => setOpen("")}
        />
      )}

      {open === "subtypes" && (
        <Select
          type={"subtypes"}
          options={complainData.allSubTypes[compType.key]}
          handleClose={() => setOpen("")}
          handleChoose={(value) => handleSubTypeSelect(value)}
        />
      )}
    </>
  );
};

export default ConfirmDrawer;
