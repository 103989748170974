import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;

  #appt {
    font-size: 20px;
    letter-spacing: 5px;
    padding: 5px 10px;
    min-width: 40%;
    text-align: center;
    border-radius: 5px;
    border: 1px solid gray;
  }

  #appt::-webkit-calendar-picker-indicator {
    display: none;
  }

  button {
    font-size: 18px;
    padding: 8px 24px;
    border-radius: 6px;
    border: 1px solid black;
    background-color: black;
    color: white;
    font-weight: 600;
    flex-shrink: 0;
    cursor: pointer;
  }
`;

export default Container;
