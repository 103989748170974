import React from "react";
const gpt = require("../../Assets/bhartGPT.png");
const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 14px",
      }}
    >
      <a
        href="https://corover.ai/privacy-policy/"
        target="_blank"
        style={{ textDecoration: "none", color: "black", fontSize: "10px" }}
      >
        Privacy Policy
      </a>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "10px",
          columnGap: "5px",
          padding: "7px",
          paddingTop: "3px",
          cursor: "pointer",
        }}
        onClick={() => {
          window.open("https://corover.ai/bharatgpt/", "_blank");
        }}
      >
        <p>Powered by </p>
        <img style={{ width: "50px" }} src={gpt} alt="" />
      </div>
      <a
        href="https://corover.ai/terms-conditions/"
        style={{ textDecoration: "none", color: "black", fontSize: "10px" }}
        target="_blank"
      >
        Terms and Conditions
      </a>
    </div>
  );
};

export default Footer;
