import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 0px 0px;
  box-shadow: 0px -4px 3px rgb(191 191 191 / 75%);
  z-index: 2;
  background: white;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 20px;
    padding-top: 20px;

    p {
      font-size: 14px;
    }

    span {
      cursor: pointer;
    }
  }

  .options {
    max-height: 400px;
    overflow-y: auto;
    div {
      font-size: 16px;
      padding: 12px 20px;
      border-bottom: 1px solid gainsboro;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;
export default Container;
