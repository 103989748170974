import axios from "axios";

export const sendFeedback = async ({ id, feedback, comment }) => {
  let payload = {
    answerId: id,
    feedback: feedback,
    comment: comment,
  };

  try {
    const response = await axios.post(`/gptAPI/railGPT/feedback`, payload);

    return { error: false, ...response.data };
  } catch (e) {
    return { error: true, status: e.response.status };
  }
};
