import moment from "moment";
import React, { useState } from "react";
import { CloseDrawer } from "../../../../../Helpers/EventEmitter";
import useSendQuery from "../../../../../Hooks/useSendQuery";
import Drawer from "../../../../../UI/Drawer";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

const DateTimePicker = ({ handleClose }) => {
  const [date, setDate] = useState(moment().format("DD:MM:YYYY"));
  const [time, setTime] = useState("00:00");
  const { sendQueryPayload } = useSendQuery();
  const handleClick = () => {
    // alert(date + " " + time);
    sendQueryPayload({
      query: `${moment(date, "DD:MM:YYYY").format("DD MMM, YYYY")} - ${moment(
        time,
        "HH:mm"
      ).format("HH:mm")}`,
      inputType: "",
      data: `${moment(date, "DD:MM:YYYY").format("DD/MM/YYYY")} ${moment(
        time,
        "HH:mm"
      ).format("HH:mm")}`,
      cxpayload: null,
    });
    CloseDrawer();
  };

  return (
    <Drawer
      header={<Header />}
      content={<Content setDate={setDate} date={date} />}
      footer={
        <Footer time={time} setTime={setTime} handleClick={handleClick} />
      }
      handleCloseDrawer={handleClose}
      showCross={true}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default DateTimePicker;
