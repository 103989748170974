import React from "react";
import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import Container from "./styles";

const Footer = ({ time, setTime, handleClick }) => {
  const handleChange = (e) => {
    setTime(e.target.value);
  };

  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;

  return (
    <Container>
      <div style={{ width: "100%" }}>
        <p style={{ marginBottom: "5px", marginLeft: "4px", fontSize: "14px" }}>
          Time:{" "}
        </p>
        <input
          type="time"
          id="appt"
          name="appt"
          value={time}
          onChange={handleChange}
        />
      </div>

      <button onClick={handleClick}>
        {lang === "en" ? "Send" : "सबमिट करें"}
      </button>
    </Container>
  );
};

export default Footer;
