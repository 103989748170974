import React, { useRef } from "react";
import ReactDOM from "react-dom";
import useGetReduxState from "../../../../Hooks/useGetReduxState";

const langText = {
  en: {
    header: "Sorry for your inconvenience",
    placeholder: "Please provide your feedback...",
    cancel: "Cancel",
    submit: "Submit",
  },
  hi: {
    header: "आपकी असुविधा के लिए खेद है",
    placeholder: "कृपया अपनी प्रतिक्रिया दें...",
    cancel: "रद्द करें",
    submit: "सबमिट करें",
  },
};

const Dislike = ({ handle, handleCancel }) => {
  const ref = useRef<HTMLTextAreaElement>();
  const handleSubmit = () => {
    if (ref?.current?.value !== "") handle(ref?.current?.value);
  };

  const lang: "hi" | "en" = useGetReduxState().behaviour.lang;

  return ReactDOM.createPortal(
    <div
      style={{ position: "absolute", inset: "0px", background: "#00000054" }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          width: "86%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          borderRadius: "16px",
          flexDirection: "column",
        }}
      >
        <h3 style={{ marginBottom: "15px" }}>{langText[lang].header}</h3>

        <textarea
          ref={ref}
          name=""
          id=""
          cols={30}
          rows={5}
          placeholder={langText[lang].placeholder}
          style={{
            width: "100%",
            padding: "7px 10px",
            fontFamily: "sans-serif",
            border: "1px solid gray",
            borderRadius: "5px",
            outline: "none",
          }}
        ></textarea>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <button
            style={{
              marginRight: "10px",
              fontSize: "14px",
              padding: "6px 16px",
              background: "white",
              border: "1px solid black",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            {langText[lang].cancel}
          </button>

          <button
            style={{
              fontSize: "14px",
              padding: "6px 16px",
              background: "black",
              border: "1px solid black",
              borderRadius: "4px",
              cursor: "pointer",
              color: "white",
            }}
            onClick={handleSubmit}
          >
            {langText[lang].submit}
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("corover-body")
  );
};

export default Dislike;
