import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { sendFeedback } from "../../../../Api/feedback";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
const like = require("../../../../Assets/like.png");

const Like = () => {
  useEffect(() => {}, []);
  const lang = useGetReduxState().behaviour.lang;

  return ReactDOM.createPortal(
    <div
      style={{ position: "absolute", inset: "0px", background: "#00000054" }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          width: "86%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          borderRadius: "16px",
          flexDirection: "column",
        }}
      >
        <img style={{ width: "105px" }} src={like} alt="" />
        <h3 style={{ marginTop: "25px" }}>
          {lang === "en"
            ? "Thank you for your feedback"
            : "आपकी प्रतिक्रिया के लिए आपका धन्यवाद"}{" "}
        </h3>
      </div>
    </div>,
    document.getElementById("corover-body")
  );
};

export default Like;
