import React, { useEffect, useState } from "react";
import { CloseDrawer } from "../../../../Helpers/EventEmitter";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import { toggleMic } from "../../../../Store/Dispatcher/behaviour";
import TrainPicker from "./StationPicker";

const SelectStation = () => {
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const loader = useGetReduxState().behaviour.showMessageLoader;

  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;
  const handleClick = () => {};
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  const state = useGetReduxState().behaviour;

  // useEffect(() => {
  //   if (
  //     state.audio.url === "" &&
  //     state.input_mode === "VOICE" &&
  //     state.currentStep &&
  //     state.currentStep !== "messsage" &&
  //     state.currentStep.length > 2
  //   ) {
  //     toggleMic();
  //   }
  // }, [state.audio.current]);

  if (hide) return <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      <div
        className="select-train-input"
        onClick={() => {
          if (!loader) setOpen(true);
        }}
      >
        <input
          readOnly
          type="text"
          placeholder={
            lang === "en"
              ? "Please select your station"
              : "कृपया अपना स्टेशन चुनें"
          }
        />
      </div>
      {open && (
        <TrainPicker
          handleClick={handleClick}
          handleClose={() => setOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export default SelectStation;
