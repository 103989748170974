import styled from "styled-components";

const Container = styled.div`
  background-color: white;
  flex-grow: 1;
  transition: all 0.5s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 30px;
  position: relative;
  /* max-height: calc(100% - 400px - 60px); */
`;
export default Container;
