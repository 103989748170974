import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 5px;
  margin-top: 20px;

  .loader-container {
    background-color: #ebecef80;
    border-radius: 8px;
    padding: 15px;
    max-width: 215px;
    text-align: left;
    padding-top: 0px;
    border-top-left-radius: 0px;
  }

  .loader-dots {
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: #808080;
    margin-right: 2px;
    animation: bounce 0.5s ease infinite alternate;

    &:nth-child(1) {
      animation-delay: 0.2s;
    }

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(5px);
    }
  }

  .avatar {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin-right: 2px;
    margin-left: 13px;

    img {
      width: 100%;
    }
  }
`;
export default Container;
