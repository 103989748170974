import styled from "styled-components";

const Container = styled.div`
  /* position: absolute; */
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: white;
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  /* border-top-left-radius: 14px;
  border-top-right-radius: 14px; */
  padding: 15px;
  /* padding-bottom: 5px; */
  padding-bottom: 0px;
  padding-top: 2px;
  position: relative;

  .refresh {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .cross {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 16px;
    font-family: sans-serif;
    cursor: pointer;
  }

  .complaint {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    padding: 8px 14px;
    width: 75%;
    min-width: 300px;
  }

  .first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .ref-id {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
      }

      p {
        margin-left: 5px;
        font-size: 14px;
      }
    }
  }

  .type {
    /* display: flex; */
    margin-bottom: 10px;
    display: none;
    .label {
      font-size: 14px;
    }

    div {
      margin-left: 5px;

      p {
        font-size: 14px;
      }

      span {
        font-size: 12px;
        display: block;
        color: gray;
      }
    }
  }

  .date {
    font-size: 12px;
  }

  .status {
    font-size: 14px;
    margin-left: 6px;
    border: 1px solid gray;
    padding: 0px 5px;
    border-radius: 3px;
  }

  .second-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      font-size: 13px;
      padding: 0px 3px;
      border: none;
      color: #2196f3;
      background: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 600;
    }
  }
`;

export default Container;
