import Container from "./styles";
const logo = require("../../../../Assets/logo.svg").default;
const Loader = () => {
  return (
    <Container>
      <div className="avatar">
        <img src={logo} alt="" />
      </div>
      <div className="loader-container">
        <span className="loader-dots"></span>
        <span className="loader-dots"></span>
        <span className="loader-dots"></span>
      </div>
    </Container>
  );
};

export default Loader;
