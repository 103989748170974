import React, { useEffect, useRef, useState } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import useSendQuery from "../../../../Hooks/useSendQuery";
import { toggleMic } from "../../../../Store/Dispatcher/behaviour";
import LanguageMenu from "./LanguageMenu";
import PNRorUTS from "./PNRorUTS";
const mic = require("../../../../Assets/mic.svg").default;
const sendbtn = require("../../../../Assets/send.png");

const langText = {
  en: {
    mob: "Provide your mobile number",
    pnr: "Provide your PNR number",
    uts: "Provide your UTS number",
    comp: "Describe your complaint here...",
    def: "Ask your query here...",
  },
  hi: {
    mob: "अपना मोबाइल नंबर दर्ज करे",
    pnr: "अपना PNR नंबर दर्ज करें",
    uts: "अपना UTS नंबर दर्ज करें",
    comp: "अपनी शिकायत यहां बताएं...",
    def: "यहां अपना प्रश्न पूछें...",
  },
};

const TextAndMic = () => {
  const [showLang, setShowLang] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState("");
  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;
  const audio = useGetReduxState().behaviour.audio;
  const inputMode = useGetReduxState().behaviour.input_mode;
  const currentStep = useGetReduxState().behaviour.currentStep;
  const nextStep = useGetReduxState().behaviour.nextContext;
  const { sendQueryPayload } = useSendQuery();
  const confirmD = useGetReduxState().complaint.show;
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && ref.current.value !== "") {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    let value = ref.current.value.trim();

    if (value !== "") {
      if (currentStep === "mobileNumber") {
        localStorage.setItem("phone", value);
      }
      //   handleSendQueryPayload({ query: value, inputType: "TEXT" });
      sendQueryPayload({
        query: value,
        inputType: currentStep === "pnr" ? "" : "TEXT",
        data: null,
        cxpayload: null,
      });
      ref.current.value = "";
      setKey("");
    }
  };

  useEffect(() => {
    if (currentStep === "mobileNumber" && localStorage.getItem("phone")) {
      setKey(localStorage.getItem("phone"));
      ref.current.value = localStorage.getItem("phone");
    }
  }, [currentStep]);

  useEffect(() => {
    if (
      audio.url === "" &&
      inputMode === "VOICE" &&
      currentStep &&
      currentStep !== "messsage" &&
      currentStep.length > 2 &&
      nextStep &&
      !confirmD
    ) {
      toggleMic();
    }
  }, [audio.current]);

  const nextContext = useGetReduxState().behaviour.nextContext;

  const getPlaceholder = () => {
    if (nextContext === "station,regMobileFlag") return langText[lang].mob;
    if (nextContext?.includes("train,3") && num === "PNR")
      return langText[lang].pnr;
    if (nextContext?.includes("train,3") && num === "UTS")
      return langText[lang].uts;
    if (nextContext?.includes("train,1")) return langText[lang].comp;

    return langText[lang].def;
  };

  const [num, setNum] = useState(sessionStorage.getItem("typeOfNum") || "PNR");
  const [showNumDrawer, setShowNumDrawer] = useState(false);

  return (
    <React.Fragment>
      {nextContext?.includes("train,3") ||
      nextContext?.includes("station,pnrUtsFlag") ? (
        <div
          style={{
            background: "black",
            borderRadius: "8px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "9px 28px",
            columnGap: "10px",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={() => setShowNumDrawer((prev) => !prev)}
        >
          {showNumDrawer && (
            <PNRorUTS
              handleChange={(val) => {
                setNum(val);
                setShowNumDrawer(false);
                sessionStorage.setItem("typeOfNum", val);
              }}
              num={num === "UTS" ? "PNR" : "UTS"}
            />
          )}
          {num} <span style={{ fontSize: "12px", color: "white" }}>▼</span>
        </div>
      ) : (
        <div className="lang-menu" onClick={() => setShowLang(true)}>
          {lang === "en" ? "EN" : "आ"}
        </div>
      )}

      <div className="input" onClick={() => setShowNumDrawer(false)}>
        {nextContext === "station,regMobileFlag" && (
          <span style={{ display: "block", marginLeft: "10px" }}>+91</span>
        )}

        <input
          type="text"
          placeholder={getPlaceholder()}
          onKeyDown={handleKeyDown}
          ref={ref}
          onChange={(e) => setKey(e.target.value.trim())}
        />
        <div
          className="mic"
          onClick={() => {
            if (key === "") toggleMic();
            else handleSendMessage();
          }}
        >
          <img
            style={{ marginLeft: key === "" ? "0px" : "4px" }}
            src={key === "" ? mic : sendbtn}
            alt=""
          />
        </div>
      </div>
      {showLang && <LanguageMenu handleClose={() => setShowLang(false)} />}
    </React.Fragment>
  );
};

export default TextAndMic;
