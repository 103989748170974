import moment from "moment";
import React, { useEffect, useState } from "react";
// @ts-ignore
import MobileCalendar from "react-scroll-calendar";

const Content = ({ setDate, date }) => {
  const [value, setValue] = useState("10:00");

  const onChange = (timeValue) => {
    setValue(timeValue);
  };

  useEffect(() => {
    document.getElementsByClassName("drawer-scroll-container")[0]?.scrollTo({
      top: document.getElementsByClassName("drawer-scroll-container")[0]
        ?.scrollHeight,
      behavior: "auto",
    });
  }, []);

  return (
    <MobileCalendar
      minDate={moment().subtract(120, "days")}
      selectedDate={moment(date, "DD:MM:YYYY")}
      maxDate={moment()}
      enableYearTitle={false}
      onSelect={(date) => {
        setDate(date.format("DD:MM:YYYY"));
      }}
    />
  );
};

export default Content;
