import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  /* background-color: #f7eaf6; */
  background-color: white;
  max-height: 400px;
  height: ${(props) => (props.isSmall ? "70px" : "40%")};
  transition: all 0.6s ease-in-out;
  flex-shrink: 0;
  overflow: hidden;

  .text {
    margin-left: ${(props) => (props.isSmall ? "10px" : "0px")};
    transition: all 0.6s ease-in-out;
    flex-shrink: 0;
  }

  .hero {
    background-image: url("./bg.svg");
    background-position: center;
    background-size: cover;
    text-align: ${(props) => (props.isSmall ? "left" : "center")};
    height: 100%;
    display: flex;
    flex-direction: ${(props) => (props.isSmall ? "row" : "column")};
    justify-content: ${(props) => (props.isSmall ? "flex-start" : "flex-end")};
    align-items: center;
    padding: 10px 16px;
    transition: all 0.6s ease-in-out;

    img {
      width: ${(props) => (props.isSmall ? "45px" : "105px")};
      transition: all 0.6s ease-in-out;
    }

    h1 {
      letter-spacing: 2px;
      font-size: ${(props) => (props.isSmall ? "28px" : "36px")};
      margin-top: ${(props) => (props.isSmall ? "0px" : "14px")};
      transition: all 0.6s ease-in-out;
    }

    p {
      font-size: ${(props) => (props.isSmall ? "10px" : "16px")};
      margin-top: 0px;
      margin-bottom: ${(props) => (props.isSmall ? "0px" : "10%")};
      transition: all 0.6s ease-in-out;
    }
  }
`;
export default Container;
