import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.isBot ? "26px" : "42px")};
  margin-left: ${(props) => (props.isBot ? "14px" : "0px")};

  display: flex;
  justify-content: ${(props) => (props.isBot ? "flex-start" : "flex-end")};
  align-items: flex-start;

  .text {
    background-color: ${(props) => (props.isBot ? "#ebecef80" : "black")};
    padding: 14px 16px;
    width: 100%;
    border-radius: 10px;
    border-top-left-radius: ${(props) => (props.isBot ? "0px" : "14px")};
    border-bottom-right-radius: ${(props) => (props.isBot ? "14px" : "0px")};
    color: ${(props) => (props.isBot ? "black" : "white")};
    margin-right: ${(props) => (props.isBot ? "0px" : "14px")};
    max-width: fit-content;
    min-width: ${(props) => (props.isBot ? "104px" : "0px")};
    overflow-wrap: break-word;

    a {
      text-decoration: none;
      color: cornflowerblue;
    }
  }

  .text-container {
    display: flex;
    justify-content: ${(props) => (props.isBot ? "flex-start" : "flex-end")};
    max-width: 80%;
    align-items: flex-start;
    position: relative;
  }

  .avatar {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin-right: 6px;

    img {
      width: 100%;
    }
  }

  .speaker {
    height: 17px;
    width: 17px;
    background: black;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    cursor: pointer;
    position: absolute;
    right: -6px;
    top: 8px;

    img {
      width: 100%;
    }
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .input-cursor {
    display: inline-block;
    width: 5px;
    height: 14px;
    background-color: #6e6e6e;
    margin-left: 0px;
    animation: 0.6s linear 0s infinite alternate none running blink;
  }

  .refresh {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
export default Container;
