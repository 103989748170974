import React, { useEffect, useState } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import DateTimePicker from "./DateTimePicker";

const SelectDate = () => {
  const [open, setOpen] = useState(false);
  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;
  const loader = useGetReduxState().behaviour.showMessageLoader;
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div className="select-train-input" onClick={() => setOpen(true)}>
        <input
          readOnly
          type="text"
          placeholder={
            lang === "en" ? "Please select your date" : "कृपया तिथि चुनें"
          }
        />
      </div>

      {open && !loader && <DateTimePicker handleClose={() => setOpen(false)} />}
    </React.Fragment>
  );
};

export default SelectDate;
