import React, { useEffect, useRef, useState } from "react";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import Loader from "./Loader";
import Message from "./Message";
import Container from "./styles";
import { useElementSize } from "@mantine/hooks";
import { setFullScreen } from "../../../Store/Dispatcher/behaviour";
import Complaints from "./Complaints";
import { getComplaints } from "../../../Api/complaints";
import useSendQuery from "../../../Hooks/useSendQuery";
import Buttons from "../../../Templates/Buttons";

const langTrxt = {
  en: {
    text: "Hi! I'm RailGPT. Let me know if you have any questions or requests.",
    voice:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/7150c21b-47f9-4b5d-84ef-daa98fe0bbab_en.wav",

    select: "Select the topic or write your query below",

    buttons: {
      "1": "Register a complaint",
      "2": "Track my concern",
      // "3": "View my registered complaints",
    },
  },
  hi: {
    text: "नमस्ते! मैं RailGPT हूं। यदि आपके कोई प्रश्न या अनुरोध हैं तो मुझे बताएं।",
    voice:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/7fb2ba65-ecec-49a5-9949-64a356e93daa_hi.wav",
    select: "विषय का चयन करें या नीचे अपनी क्वेरी पूछें",
    buttons: {
      "1": "शिकायत दर्ज करें",
      "2": "मेरी शिकायत ट्रैक करें",
    },
  },
};

const Messages = () => {
  const state = useGetReduxState();
  const messages = useGetReduxState().messages;
  const messagesLoader = useGetReduxState().behaviour.showMessageLoader;
  const scrollToBottom = () => {
    messagesRef.current.scrollTo({
      top: messagesRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const messagesRef = useRef<HTMLDivElement>(null);
  const blink = useGetReduxState().behaviour.blink;
  useEffect(() => {
    scrollToBottom();
  }, [messages, messagesLoader, blink]);

  const lang = useGetReduxState().behaviour.lang;

  return (
    <Container ref={messagesRef}>
      {state.messages.map((message, index) => {
        if (message.type === "TEXT")
          return (
            <Message
              id={message.id}
              key={index}
              text={message.text}
              audioUrl={message.audioUrl}
              sender={message.sender}
              time={message.timeStamp}
              isGPT={message.isGPT}
            />
          );
        else {
          return (
            <div
              style={{
                width: "100%",
                marginTop: "15px",
              }}
            >
              {message.component}
            </div>
          );
        }
      })}

      {state.behaviour.showMessageLoader && <Loader />}
    </Container>
  );
};

export default Messages;
