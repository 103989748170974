import React from "react";

const PNRorUTS = ({ num, handleChange }) => {
  return (
    <div
      style={{
        position: "absolute",
        color: "white",
        background: "black",
        left: "0",
        right: "0",
        textAlign: "center",
        top: "-32px",
        padding: "10px 0px",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleChange(num);
      }}
    >
      {num}
    </div>
  );
};

export default PNRorUTS;
