import React, { useEffect } from "react";
import Container from "./styles";
import styled from "styled-components";
import { useState } from "react";
import useGetReduxState from "../../Hooks/useGetReduxState";
import { deleteLastNmessages } from "../../Store/Dispatcher/messages";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 6px;
  outline: none;
  background-color: ${(props) =>
    props.type === "default" ? "#F5F8FC" : "green"};
  border: 1px solid;
  border-color: ${(props) => (props.type === "default" ? "#D0CDF0" : "green")};
  color: ${(props) => (props.type === "default" ? "black" : "yellow")};
  cursor: pointer;
  font-weight: 500;
  margin-top: 8px;
  min-width: 250px;
  font-size: 16px;

  img {
    width: 32px;
    margin-right: 10spx;
  }
`;

const Buttons = ({
  header,
  buttons,
  render,
}: {
  header: string;
  buttons: any;
  render?: string;
}) => {
  const [hidden, setHide] = useState(false);
  const currentStep = useGetReduxState().behaviour.currentStep;

  return (
    <Container hidden={hidden}>
      {header && <p>{header}</p>}

      {buttons.map((button) => (
        <Button
          type={button.type}
          onClick={() => {
            deleteLastNmessages(1);
            button.handleClick();
            setHide(true);
          }}
        >
          {button.icon && <img src={button.icon} alt="" />}
          {button.text}
        </Button>
      ))}
    </Container>
  );
};

export default Buttons;
