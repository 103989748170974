import moment from "moment";
import React from "react";
import Container from "./styles";

const ComplaintInfo = ({ data }) => {
  const getColor = () => {
    let c = data.status.toLowerCase();

    if (c === "registered") return "#fc9b94";

    if (c === "closed") return "#60c964";

    return "gray";
  };

  return (
    <Container>
      <div>
        <span>Reference ID: </span>
        <p>{data.complaintReferenceNo}</p>
      </div>

      <div>
        <span>Incident Date: </span>
        <p>
          {moment(data.incidentDate, "DD/MM/YYYY hh:mm").format(
            "DD MMM, YYYY | hh:mm"
          )}
        </p>
      </div>

      <div
        style={{
          width: "100%",
          borderTop: "1px solid #e5e5e5",
          marginTop: "10px",
        }}
      ></div>

      <div>
        <span>Complaint Type: </span>
        <p>{data.complaint}</p>
      </div>

      <div>
        <span>Sub Complaint Type: </span>
        <p>{data.subComplaint}</p>
      </div>

      <div className="flex">
        <p
          style={{
            color: "white",
            borderColor: getColor(),
            backgroundColor: getColor(),
          }}
        >
          {data.status}
        </p>
      </div>

      {data.status?.toLowerCase() === "closed" && (
        <div
          style={{
            marginTop: "10px",
            display: "block",
            borderTop: "1px solid gainsboro",
            paddingTop: "10px",
          }}
        >
          <span>Remark:</span>

          <p
            style={{
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            {data.remark}
          </p>
        </div>
      )}

      <div>
        <span>Message: </span>
        <p>{data.message}</p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ margin: 0, textAlign: "center" }}>
          <span>Division: </span>
          <p>{data.division}</p>
        </div>

        <div style={{ margin: 0, textAlign: "center" }}>
          <span>Department: </span>
          <p>{data.department}</p>
        </div>
      </div>
    </Container>
  );
};

export default ComplaintInfo;
