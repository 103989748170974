import moment from "moment";
import React, { useEffect, useState } from "react";
// @ts-ignore
import MobileCalendar from "react-scroll-calendar";

const Content = ({ setDate, date, handleClick }) => {
  return (
    <MobileCalendar
      minDate={moment().subtract(2, "days")}
      selectedDate={moment(date, "DD:MM:YYYY")}
      maxDate={moment().add(120, "days")}
      enableYearTitle={false}
      onSelect={(date) => {
        handleClick(date.format("DD:MM:YYYY"));
      }}
    />
  );
};

export default Content;
