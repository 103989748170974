import styled from "styled-components";

const Container = styled.div`
  margin-left: 40px;
  background-color: white;
  width: fit-content;
  min-width: 80%;
  max-width: 80%;
  margin-top: 46px;
  padding: 15px;
  color: black;
  font-size: 12px;
  border-radius: 7px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  position: relative;

  div {
    display: block;
    align-items: center;
    justify-content: flex-start;
    column-gap: 12px;
    margin-top: 12px;

    &:first-child {
      margin-top: 0px;
    }

    span {
      font-weight: 600;
    }

    p {
      margin-top: 2px;
      font-size: 16px;
      color: #565555;
    }
  }

  div.flex {
    display: flex;
    position: absolute;
    width: fit-content;
    top: 14px;
    right: 14px;
    margin: 0;

    p {
      font-weight: 400;
      background: orange;
      color: white;
      font-size: 16px;
      padding: 2px 10px;
      border-radius: 4px;
    }
  }
`;

export default Container;
