import axios from "axios";
import store from "../Store";

export const getOtp = async (mobile) => {
  const config = store.getState().behaviour;
  let payload = {
    userToken: config.userToken,
    mobileNumber: mobile,
    //   next_context: configuration.nextContext,
    //   userAgent: navigator.userAgent,
    //   inputType: configuration.input_mode,
    //   cxpayload: null,
    //   token: btoa(localStorage.getItem("phoneNumber")),
  };

  try {
    const response = await axios.post(`/gptAPI/railGPT/login`, payload);

    const data = response.data;

    if (data.status === 200) return { error: false, message: "" };
    else
      return {
        error: true,
        message: "Check mobile number or try again after some time",
      };
  } catch (e) {
    return {
      error: true,
      message: "Check mobile number or try again after some time",
    };
  }
};

export const verifyOtp = async (otp) => {
  const config = store.getState().behaviour;

  let payload = {
    userToken: config.userToken,
    otp: otp,
  };

  try {
    const response = await axios.post(`/gptAPI/railGPT/verifyLogin`, payload);

    const data = response.data;

    if (data.status === 200) {
      localStorage.setItem("jwt", data.token);
      return { error: false, message: "" };
    } else
      return {
        error: true,
        message: "Enter otp again or try again after some time",
      };
  } catch (e) {
    return {
      error: true,
      message: "Enter otp again or try again after some time",
    };
  }
};
