import React, { useState } from "react";
import Microphone from "../../Chatbot/Body/Microphone";
import Fuse from "fuse.js";
import useGetReduxState from "../../Hooks/useGetReduxState";

const micIcon = require("../../Assets/mic.svg").default;

const Select = ({ type, options, handleChoose, handleClose }) => {
  const lang = useGetReduxState().behaviour.lang;
  const getHeader = () => {
    if (lang === "en") {
      if (type === "types") return "Please select complaint type";
      else return "Please select sub-complaint type";
    } else {
      if (type === "types") return "कृपया शिकायत का प्रकार चुनें";
      else return "कृपया शिकायत का उप-प्रकार चुनें";
    }
  };

  const coptions = {
    includeScore: true,
    keys: ["label", "value"],
  };

  const fuse = new Fuse(options, coptions);

  const [mic, setMic] = useState(false);

  const handleVoice = (value) => {
    if (value) {
      const result = fuse.search(value);

      if (result.length > 0) {
        if (type === "types") handleChoose(result[0].item);
        else
          handleChoose({
            label: result[0].item["value"],
            key: result[0].item["key"],
          });
      }
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        left: "0",
        right: "0",
        bottom: "0",
        top: 0,
        background: "#000000ad",
        zIndex: "999999",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
      // onClick={() => handleClose()}
    >
      <div
        style={{
          width: "100%",
          background: "white",
          padding: "10px 0px",
          maxHeight: "400px",
        }}
      >
        <div
          style={{
            padding: "5px 10px",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontSize: "14px",
              fontWeight: "500",
              marginBottom: "10px",
            }}
          >
            {getHeader()}
          </h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={micIcon}
              alt=""
              style={{ width: "18px", cursor: "pointer", marginRight: "20px" }}
              onClick={() => setMic(true)}
            />
            <span style={{ cursor: "pointer" }} onClick={() => handleClose()}>
              X
            </span>
          </div>
        </div>

        {type === "types" ? (
          <div style={{ maxHeight: "100%", overflowY: "auto" }}>
            {options.map((option) => (
              <p
                style={{
                  fontSize: "16px",
                  padding: "8px 10px",
                  borderTop: "1px solid gainsboro",
                  cursor: "pointer",
                }}
                onClick={() => handleChoose(option)}
              >
                {option.label}
              </p>
            ))}
          </div>
        ) : (
          <div style={{ maxHeight: "100%", overflowY: "auto" }}>
            {options.map((option) => (
              <p
                style={{
                  fontSize: "16px",
                  padding: "8px 10px",
                  borderTop: "1px solid gainsboro",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleChoose({ label: option.value, key: option.key })
                }
              >
                {option.value}
              </p>
            ))}
          </div>
        )}
      </div>

      {mic && (
        <div style={{ bottom: 0, width: "100%", background: "white" }}>
          <Microphone
            handleVoice={(value) => {
              // filterTrains(value);
              handleVoice(value);
              setMic(false);
            }}
            handleVoiceOptions={({ label, value }) => {
              // filterTrains(value);
              setMic(false);
            }}
            handleClose={() => setMic(false)}
            useEnglish={false}
          />
        </div>
      )}
    </div>
  );
};

export default Select;
