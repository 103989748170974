import { combineReducers } from "redux";

import messages from "./messages";
import behaviour from "./behaviour";
import complaint from "./complaint";
// import train from "./train";
// import journey from "./journey";
// import faqs from "./faqs";
// import app from "./app";
// import user from "./user";
// import passengerform from "./passengerForm";
// import utils from "./utils";

export const rootReducer = combineReducers({
  messages,
  behaviour,
  complaint,
});

export type RootState = ReturnType<typeof rootReducer>;
