import React, { useEffect, useRef, useState } from "react";
import Container from "./styles";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import { stopAudio } from "../../../Store/Dispatcher/behaviour";
import Fuse from "fuse.js";
import useRecorder from "./useRecorder";
import { getAudioURL } from "../../../Api/audioURL";
import { flushSync } from "react-dom";

const logo = require("../../../Assets/logo.svg").default;
const mic = require("../../../Assets/mic-big.png");
const Microphone = ({
  handleVoice,
  handleClose,
  useEnglish,
  handleVoiceOptions,
}) => {
  const options = {
    includeScore: true,
    keys: ["label"],
  };

  const { transcript, listening } = useSpeechRecognition();
  const queryLang = useGetReduxState().behaviour.lang;
  const [isloaded, setIsLoaded] = useState(false);
  const dataEn = useGetReduxState().behaviour.options;
  const fuse = new Fuse(dataEn, options);
  const current = useGetReduxState().behaviour.currentStep;
  const ref = useRef<Blob>();

  const recorder = useRecorder((blob) => {
    ref.current = blob;
  });

  let lang = "en-IN";
  if (useEnglish) lang = "en-IN";
  else lang = `${queryLang}-IN`;

  const startRecognition = () => {
    setIsLoaded(true);
    SpeechRecognition.startListening({
      continuous: false,
      language: lang,
    });
    // recorder.startRecording();
  };

  const stopRecognition = () => {
    // recorder.saveRecording();
    SpeechRecognition.stopListening();
  };

  const allDone = (t) => {
    setTimeout(async () => {
      await getAudioURL(ref.current);
      handleVoice(t);
    }, 1);
  };

  useEffect(() => {
    stopAudio();
    if (!listening && !isloaded) {
      startRecognition();
    }
  }, []);

  useEffect(() => {
    if (!listening && isloaded) {
      let t = transcript;
      if (current === "mobileNumber" || current === "pnr") {
        t = transcript.trim().split(" ").join("");
      }

      if (
        (current === "complainName" || current === "commodity") &&
        dataEn &&
        dataEn.length > 0
      ) {
        const result = fuse.search(t);
        if (result.length > 0) {
          // @ts-ignore
          handleVoiceOptions({
            // @ts-ignore
            label: result[0].item.label,
            // @ts-ignore
            value: result[0].item.value,
          });
        } else {
          allDone(t);
        }
      } else {
        allDone(t);
      }

      stopRecognition();
      handleClose();
    }
  }, [listening]);

  useEffect(() => {
    console.log(transcript);
  }, [transcript]);

  return (
    <Container>
      <div className={"microphone-new-active"}>
        <img src={mic} alt="" />
      </div>
    </Container>
  );
};

export default Microphone;
