import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 48px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  flex-shrink: 0;

  .lang-menu {
    width: 45px;
    height: 40px;
    border-radius: 50%;
    background-color: #f3e0f0;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .input,
  .select-train-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 42px;
    border: 1px solid #d8d6d6;
    border-radius: 20px;
    margin-left: 10px;

    input {
      width: 100%;
      font-size: 18px;
      outline: none;
      border: none;
      margin-left: 10px;
      margin-right: 10px;
    }

    .mic {
      width: 45px;
      height: 40px;
      border-radius: 50%;
      background-color: #efefef;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px;

      img {
        width: 100%;
      }
    }
  }

  .select-train-input {
    cursor: pointer;
    margin: 0;

    input {
      cursor: pointer;
    }
  }
`;
export default Container;
