import moment from "moment";
import React, { useState } from "react";
import { CloseDrawer } from "../../../../../Helpers/EventEmitter";
import useSendQuery from "../../../../../Hooks/useSendQuery";
import Drawer from "../../../../../UI/Drawer";
import Content from "./Content";
import Header from "./Header";

const DateTimePicker = ({ handleClose }) => {
  const [date, setDate] = useState(moment().format("DD:MM:YYYY"));

  const { sendQueryPayload } = useSendQuery();
  const handleClick = (date) => {
    // alert(date + " " + time);
    sendQueryPayload({
      query: `${moment(date, "DD:MM:YYYY").format("DD MMM, YYYY")}`,
      inputType: "",
      data: `${moment(date, "DD:MM:YYYY").format("DD/MM/YYYY")}`,
      cxpayload: null,
    });
    CloseDrawer();
  };

  return (
    <Drawer
      header={<Header />}
      content={
        <Content setDate={setDate} date={date} handleClick={handleClick} />
      }
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={true}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default DateTimePicker;
