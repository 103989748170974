import {
  HIDE_ALSO_TRY,
  PLAY_AUDIO,
  SET_APP_LANGUAGE,
  SET_CURRENT,
  SET_NEXT_CONTEXT,
  SET_QUERY_LANGUAGE,
  SHOW_ALSO_TRY,
  STOP_AUDIO,
  TOGGLE_MESSAGE_LOADER,
} from "../Actions/constants";
import { Behaviour } from "./types";
import { v4 as uuid4 } from "uuid";

const intialBehaviour = {
  showMessageLoader: true,
  input_mode: "TEXT",
  lang: "en",
  fullScreenComponent: { type: null, component: null },
  currentStep: "0",
  userToken: null,
  nextContext: "",
  mic: false,
  options: [{ label: "", value: "" }],
  audio: {
    url: "",
    current: "-1",
  },
  allStations: [],
  popularStations: [],
  allTrains: [],
  blink: "",
  audioURL: "",
  isAudioPending: false,
};

const reducer = (behaviour = intialBehaviour, action) => {
  switch (action.type) {
    case "TOGGLE_MESSAGE_LOADER":
      return { ...behaviour, showMessageLoader: !behaviour.showMessageLoader };

    case "TOGGLE_MIC":
      return { ...behaviour, mic: !behaviour.mic };

    case "SET_LANGUAGE":
      return { ...behaviour, lang: action.lang };

    case "SET_FULLSCREEN_COMPONENT":
      return { ...behaviour, fullScreenComponent: action.value };
    case "SET_INPUT_TYPE":
      return { ...behaviour, input_mode: action.value };
    case "SET_CURRENT_STEP":
      return { ...behaviour, currentStep: action.value };
    case PLAY_AUDIO:
      return { ...behaviour, audio: { url: action.url, current: action.id } };
    case STOP_AUDIO:
      return { ...behaviour, audio: { url: "", current: -1 } };
    case SET_NEXT_CONTEXT:
      return { ...behaviour, nextContext: action.value };
    case "SET_OPTIONS":
      return { ...behaviour, options: action.value };
    case "SET_POPULAR_STATIONS":
      return { ...behaviour, popularStations: action.value };
    case "SET_ALL_STATIONS":
      return { ...behaviour, allStations: action.value };
    case "SET_ALL_TRAINS":
      return { ...behaviour, allTrains: action.value };

    case "SET_BLINK":
      return { ...behaviour, blink: action.value };

    case "SET_USER_TOKEN":
      return { ...behaviour, userToken: action.value };

    case "SET_AUDIOURL":
      return { ...behaviour, audioURL: action.value };
    case "TOGGLE_ISAUDIOPENDING":
      return { ...behaviour, isAudioPending: !behaviour.isAudioPending };
    default:
      return behaviour;
  }
};

export default reducer;
