import React, { useEffect, useState } from "react";
import { getComplaints } from "../../Api/complaints";
import useGetReduxState from "../../Hooks/useGetReduxState";
import useSendQuery from "../../Hooks/useSendQuery";
import { toggleMic } from "../../Store/Dispatcher/behaviour";
import ConfirmDrawer from "../../Templates/ConfirmDrawer";
import Messages from "./Messages";
import Complaints from "./Messages/Complaints";
import Microphone from "./Microphone";
import Sender from "./Sender";

const Body = () => {
  const showMic = useGetReduxState().behaviour.mic;
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(false);

  const show = useGetReduxState().complaint.show;

  const getC = async () => {
    setLoading(true);
    let num = localStorage.getItem("phoneNumber");
    let results = await getComplaints(num);
    if (results.error) setComplaints([]);
    else setComplaints(results);
    setLoading(false);
  };

  useEffect(() => {
    getC();
  }, []);

  return (
    <div
      style={{
        flexGrow: 1,

        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      id="corover-body"
    >
      {complaints.length > 0 && (
        <Complaints
          handleRefresh={getC}
          complaints={complaints}
          loading={loading}
        />
      )}
      <Messages />
      <Sender />
      {show && <ConfirmDrawer />}
    </div>
  );
};

export default Body;
