import React, { useEffect, useState } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import useSendQuery from "../../../../Hooks/useSendQuery";
import { toggleMic } from "../../../../Store/Dispatcher/behaviour";
import SelectPicker from "./SelectPicker";
const mic = require("../../../../Assets/mic.svg").default;
const down = require("../../../../Assets/down.png");

const langText = {
  en: {
    placeholdertype: "Select your complaint type",
    placeholderSub: "Select your sub-complaint type",
    placeholderCommodity: "Select your commodity",
    headertype: "Pleae Select type of your complaint",
    headerSub: "Pleae Select type of your sub-complaint",
    headerCommodity: "Please select your commodity",
  },

  hi: {
    placeholdertype: "अपनी शिकायत का प्रकार चुनें",
    placeholderSub: "अपनी शिकायत का उप-प्रकार चुनें",
    placeholderCommodity: "अपनी वस्तु चुनें",
    headertype: "कृपया अपनी शिकायत का प्रकार चुनें",
    headerSub: "कृपया अपनी शिकायत का उप-प्रकार चुनें",
    headerCommodity: "कृपया अपनी वस्तु चुनें",
  },
};

const SelectBox = ({ type }) => {
  const [open, setOpen] = useState(false);
  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;

  const options = useGetReduxState().behaviour.options;

  const getPlaceholder = () => {
    if (type === "commodity") return langText[lang].placeholderCommodity;
    return langText[lang].placeholderSub;
  };

  const getHeader = () => {
    if (type === "commodity") return langText[lang].headerCommodity;
    return langText[lang].headerSub;
  };

  const { sendQueryPayload } = useSendQuery();

  const handleClick = (label, value) => {
    sendQueryPayload({
      query: label,
      inputType: "",
      data: value.toString(),
      cxpayload: null,
    });
    setOpen(false);
    // handleClose();
  };

  const state = useGetReduxState().behaviour;

  // useEffect(() => {
  //   if (
  //     state.audio.url === "" &&
  //     state.input_mode === "VOICE" &&
  //     state.currentStep &&
  //     state.currentStep !== "messsage" &&
  //     state.currentStep.length > 2
  //   ) {
  //     toggleMic();
  //   }
  // }, [state.audio.current]);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div className="select-train-input" style={{ position: "relative" }}>
        <input
          type="text"
          placeholder={getPlaceholder()}
          readOnly
          onClick={() => setOpen(true)}
        />
        <div
          className="mic"
          onClick={() => {
            if (open) toggleMic();
            else setOpen(true);
          }}
        >
          <img
            style={{ marginLeft: open ? "0px" : "4px" }}
            src={open ? mic : down}
            alt=""
          />
        </div>
      </div>

      {open && (
        <SelectPicker
          header={getHeader()}
          options={options}
          handleClose={() => setOpen(false)}
          handleClick={handleClick}
        />
      )}
    </React.Fragment>
  );
};

export default SelectBox;

// 1.spot my train
// 2. complain
// 3. railgpt
// 4. auto open
// 5. voice drop down
// 6. hindi
// 7. powered by bharatgpt
// 8. reference id -  wrong.
// 9. feedback -
// 10. mic auto open
