import store from "../Store";
import axios from "axios";
let cancelToken;

export const sendQuery = async ({
  query,
  // next_context,
  // langCode,
  cxpayload,
  // isFallback,
}) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().behaviour;
  let payload = {
    query: query,
    userToken: configuration.userToken || null,
    next_context: configuration.nextContext,
    userAgent: navigator.userAgent,
    inputType: configuration.input_mode,
    cxpayload: cxpayload,
    token: localStorage.getItem("jwt"),
    audioURL: configuration.audioURL,
    type: sessionStorage.getItem("typeOfNum") || "PNR",
  };

  try {
    const response = await axios.post(
      `/gptAPI/railGPT/query/${configuration.lang}`,
      payload,
      {
        cancelToken: cancelToken.token,
      }
    );

    if (response.data.render == "openDisha") {
      setTimeout(() => {
        window.open("https://irctc.corover.ai", "_blank");
      }, 5500);
    }

    return { error: false, ...response.data };
  } catch (e) {
    return { error: true, status: e.response.status };
  }
};

export const regenerateAnswer = async (
  query
  // next_context,
  // langCode,

  // isFallback,
) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().behaviour;
  let payload = {
    query: query,
    userToken: configuration.userToken || null,
    next_context: configuration.nextContext,
    userAgent: navigator.userAgent,
    inputType: configuration.input_mode,
    cxpayload: null,
    token: localStorage.getItem("jwt"),
    type: sessionStorage.getItem("typeOfNum") || "PNR",
  };

  try {
    const response = await axios.post(
      `/gptAPI/railGPT/query/${configuration.lang}`,
      payload,
      {
        cancelToken: cancelToken.token,
      }
    );

    if (response.data.render == "openDisha") {
      setTimeout(() => {
        window.open("https://irctc.corover.ai", "_blank");
      }, 2000);
    }

    return { error: false, ...response.data };
  } catch (e) {
    return { error: true, status: e.response.status };
  }
};
