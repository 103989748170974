import React, { useEffect, useState } from "react";
import useSendQuery from "../../../../../Hooks/useSendQuery";
import Drawer from "../../../../../UI/Drawer";
import Microphone from "../../../Microphone";
import Container from "./styles";
import Fuse from "fuse.js";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
const img = require("../../../../../Assets/mic.svg").default;
const coptions = {
  includeScore: true,
  keys: ["label", "value"],
};

const SelectPicker = ({ header, options, handleClose, handleClick }) => {
  const [mic, setMic] = useState(false);
  const fuse = new Fuse(options, coptions);

  const audio = useGetReduxState().behaviour.audio;
  const currentStep = useGetReduxState().behaviour.currentStep;
  const inputMode = useGetReduxState().behaviour.input_mode;
  const nextStep = useGetReduxState().behaviour.nextContext;
  const confirmD = useGetReduxState().complaint.show;

  useEffect(() => {
    if (
      audio.url === "" &&
      inputMode === "VOICE" &&
      currentStep &&
      currentStep !== "messsage" &&
      currentStep.length > 2 &&
      nextStep &&
      !confirmD
    ) {
      setMic(true);
    }
  }, [audio.current]);

  const handleVoice = (value) => {
    console.log("caught");
    if (value) {
      const result = fuse.search(value);
      console.log(result);
      if (result.length > 0) {
        handleClick(result[0].item["label"], result[0].item["value"]);
      }
    }
  };

  return (
    <Container>
      <div className="header">
        <p>{header}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "12px",
          }}
        >
          <img
            style={{ width: "18px", cursor: "pointer" }}
            onClick={() => setMic(true)}
            src={img}
            alt=""
          />
          <span onClick={handleClose}>X</span>
        </div>
      </div>

      <div className="options">
        {options.map((option) => (
          <div
            className="option"
            onClick={() => handleClick(option.label, option.value)}
          >
            {option.label}
          </div>
        ))}
      </div>

      {mic && (
        <div style={{ bottom: 0, width: "100%", background: "white" }}>
          <Microphone
            handleVoice={(value) => {
              // filterTrains(value);
              // console.log(11);
              // handleVoice(value);

              setMic(false);
            }}
            handleVoiceOptions={({ label, value }) => {
              // filterTrains(value);

              handleClick(label, value);
              setMic(false);
            }}
            handleClose={() => setMic(false)}
            useEnglish={false}
          />
        </div>
      )}
    </Container>
  );
};

export default SelectPicker;
