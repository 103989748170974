import axios from "axios";
import store from "../Store";
import { setAudio, toggleIsAudioPending } from "../Store/Dispatcher/behaviour";

export const getAudioURL = async (buffer) => {
  let formData = new FormData();
  formData.append("queryAudio", buffer);

  const lang = store.getState().behaviour.lang;
  await axios
    .post("/cognitive/speech?langCode=" + lang, formData)
    .then((response) => {
      setAudio(response.data.audioUrl);
    })
    .catch((err) => {
      setAudio("");
    });
};
