import React, { useEffect } from "react";

import {
  setAllStations,
  setCurrentStep,
  setFullScreen,
  setPopularStations,
  setUserToken,
  stopAudio,
  toggleLoader,
  toggleMic,
} from "../Store/Dispatcher/behaviour";
import useSendQuery from "../Hooks/useSendQuery";
import {
  addCustomResponseMessage,
  addResponseMessage,
  deleteAllMessages,
  deleteLastNmessages,
} from "../Store/Dispatcher/messages";
import { useState } from "react";
import Buttons from "../Templates/Buttons";
import Container from "./styles";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import useGetReduxState from "../Hooks/useGetReduxState";
import Microphone from "./Body/Microphone";
import { getAllStations, getPopularStations } from "../Api/stations";
import Phone from "./Phone";

import { v4 as uuid4 } from "uuid";
import EventEmitter from "../Helpers/EventEmitter";

const langTrxt = {
  en: {
    text: "Hi! I'm RailGPT. Let me know if you have any questions or requests.",
    voice:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/7150c21b-47f9-4b5d-84ef-daa98fe0bbab_en.wav",

    select: "Select the topic or write your query below",

    buttons: {
      "1": "Register a complaint",
      "2": "Track my concern",
      // "3": "View my registered complaints",
    },
  },
  hi: {
    text: "नमस्ते! मैं RailGPT हूं। यदि आपके कोई प्रश्न या अनुरोध हैं तो मुझे बताएं।",
    voice:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/7fb2ba65-ecec-49a5-9949-64a356e93daa_hi.wav",
    select: "विषय का चयन करें या नीचे अपनी क्वेरी पूछें",
    buttons: {
      "1": "शिकायत दर्ज करें",
      "2": "मेरी शिकायत ट्रैक करें",
    },
  },
};

const Chatbot = () => {
  const loader = useGetReduxState().behaviour.showMessageLoader;
  const showMic = useGetReduxState().behaviour.mic;
  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;
  const [loaded, setLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const { sendQueryPayload } = useSendQuery();

  const fireQuery = (d) => {
    sendQueryPayload((prev) => ({ ...prev, query: d.query, data: null }));
  };

  useEffect(() => {
    const listener = EventEmitter.addListener("TRIGGER_QUERY", fireQuery);

    return () => {
      listener.remove();
    };
  }, []);

  useEffect(() => {
    let audioPlayer: any = document.getElementById(
      "myaudio"
    ) as HTMLAudioElement;

    audioPlayer.addEventListener(
      "ended",
      () => {
        stopAudio();
      },
      false
    );

    setLoaded(true);
  }, []);

  const mock = () => {
    addResponseMessage({
      text: langTrxt[lang].text,
      audioUrl: langTrxt[lang].voice,
      showAvatar: true,
      id: uuid4(),
    });

    addCustomResponseMessage({
      component: (
        <Buttons
          header={langTrxt[lang].select}
          buttons={[
            {
              text: "📣 " + langTrxt[lang].buttons[1],
              type: "default",
              handleClick: () => {
                sendQueryPayload({
                  query: langTrxt[lang].buttons[1],
                  inputType: "TEXT",
                  data: null,
                  cxpayload: null,
                });
              },
              icon: "",
            },
            {
              text: "⏱️ " + langTrxt[lang].buttons[2],
              type: "default",
              handleClick: () => {
                sendQueryPayload({
                  query: langTrxt[lang].buttons[2],
                  inputType: "TEXT",
                  data: null,
                  cxpayload: null,
                });
              },
              icon: "",
            },
          ]}
        />
      ),
      showAvatar: false,
    });

    if (loader) {
      toggleLoader();
    }
  };

  useEffect(() => {
    if (loaded) {
      mock();
    }
  }, [loaded]);

  useEffect(() => {
    if (started) {
      // addResponseMessage({
      //   text: langTrxt[lang].text,
      //   audioUrl: langTrxt[lang].voice,
      //   showAvatar: true,
      //   id: uuid4(),
      // });
      mock();
    } else {
      setStarted(true);
    }
  }, [lang]);

  const get = async () => {
    const d2 = await getPopularStations();
    setPopularStations(d2);

    const d1 = await getAllStations();
    setAllStations(d1);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    get();

    if (
      !isLoggedIn &&
      !window.location.href.includes("localhost") &&
      !localStorage.getItem("LordNilesh")
    ) {
      // const pass = prompt("Enter Password:");
      // if (pass == "BharatGPT2020")
      setIsLoggedIn(true);

      // if (pass == "LordNilesh") {
      //   localStorage.setItem("LordNilesh", "12");
      // }
    } else setIsLoggedIn(true);
  }, []);

  useEffect(() => {
    let uuid = uuid4();
    setUserToken(uuid);
  }, []);

  const currentFull = useGetReduxState().behaviour.fullScreenComponent;

  if (!isLoggedIn) return <></>;

  return (
    <Container>
      <Header />

      {/* {currentFull.type ? <>{currentFull.component}</> : <Body />} */}
      {!localStorage.getItem("jwt") ? <Phone /> : <Body />}

      {showMic && (
        <Microphone
          handleClose={() => toggleMic()}
          handleVoice={(value) => {
            if (value)
              sendQueryPayload({
                query: value,
                inputType: "VOICE",
                data: null,
                cxpayload: null,
              });
          }}
          handleVoiceOptions={({ label, value }) => {
            sendQueryPayload({
              query: label,
              inputType: "VOICE",
              data: value,
              cxpayload: null,
            });
          }}
          useEnglish={false}
        />
      )}
      <Footer />
    </Container>
  );
};

export default Chatbot;
