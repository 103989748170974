import store from "..";

import * as behaviour from "../Actions/behaviour";

export const toggleLoader = () => {
  store.dispatch(behaviour.toggleMessageLoader());
};

export const toggleMic = () => {
  store.dispatch(behaviour.toggleMic());
};

export const setLang = (lang) => {
  store.dispatch(behaviour.setLanguage(lang));
};

export const setFullScreen = ({ type, component }) => {
  store.dispatch(behaviour.setFullScreenComponent({ type, component }));
};

export const setCurrentStep = (value) => {
  store.dispatch(behaviour.setCurrentStep(value));
};

export const setOptions = (value) => {
  store.dispatch(behaviour.setOptions(value));
};

export const setInputType = (value: "TEXT" | "VOICE") => {
  store.dispatch(behaviour.setInputType(value));
};

export const playAudio = ({ id, url }: { id: any; url: string }) => {
  let audioPlayer: any = document.getElementById("myaudio") as HTMLAudioElement;
  audioPlayer.src = url;
  audioPlayer.play();
  store.dispatch(behaviour.playMessageAudio({ id: id.toString(), url: url }));
};

export const stopAudio = () => {
  let audioPlayer: any = document.getElementById("myaudio") as HTMLAudioElement;
  audioPlayer.pause();
  store.dispatch(behaviour.stopMessageAudio());
};

export const setNextContext = (value) => {
  store.dispatch(behaviour.setNextContext(value));
};

export const setPopularStations = (value) => {
  store.dispatch(behaviour.setPopular(value));
};

export const setAllStations = (value) => {
  store.dispatch(behaviour.setAll(value));
};

export const setAllTrains = (value) => {
  store.dispatch(behaviour.setAllTrains(value));
};

export const setBlink = (value) => {
  store.dispatch(behaviour.setBlink(value));
};

export const setUserToken = (value) => {
  store.dispatch(behaviour.setUserToken(value));
};

export const setAudio = (value) => {
  store.dispatch(behaviour.setAudioURL(value));
};

export const toggleIsAudioPending = () => {
  store.dispatch(behaviour.togglePending());
};
