import React from "react";
import { CloseDrawer } from "../../../../../../Helpers/EventEmitter";
import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import { toggleMic } from "../../../../../../Store/Dispatcher/behaviour";
import Container from "./styles";
const icon = require("../../../../../../Assets/magnifying-glass.png");
const mic = require("../../../../../../Assets/mic.svg").default;
const Header = ({ value, toggleMic, filterTrains }) => {
  const lang = useGetReduxState().behaviour.lang;

  return (
    <Container>
      <div className="first-row">
        <div className="input">
          <img src={icon} alt="" />
          <input
            type="text"
            value={value}
            placeholder={
              lang === "en"
                ? "Search by station name/city/code"
                : "स्टेशन के नाम/शहर/कोड द्वारा खोजें"
            }
            onChange={(e) => filterTrains(e.target.value)}
          />
          <img className="mic" src={mic} alt="" onClick={() => toggleMic()} />
        </div>
        <button onClick={() => CloseDrawer()}>
          {lang === "en" ? <>Cancel</> : <>रद्द करें</>}
        </button>
      </div>
      <p>
        {lang === "en" ? (
          <>Please select your station below</>
        ) : (
          <>कृपया नीचे अपनी स्टेशन का चयन करें</>
        )}
      </p>
    </Container>
  );
};

export default Header;
