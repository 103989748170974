import React from "react";
import Container from "./styles";
import styled from "styled-components";
import useSendQuery from "../../../../../../Hooks/useSendQuery";
import { CloseDrawer } from "../../../../../../Helpers/EventEmitter";
import Microphone from "../../../../Microphone";
import useGetReduxState from "../../../../../../Hooks/useGetReduxState";

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 12px;
  border-bottom: 1px solid gainsboro;
  cursor: pointer;
  span {
    font-size: 16px;
    background-color: black;
    color: white;
    padding: 3px 8px;
    border-radius: 4px;
    font-weight: 500;
    display: block;
    margin-right: 14px;
  }

  p {
    font-size: 14px;
  }
`;

const Content = ({ trains, hide, value }) => {
  const { sendQueryPayload } = useSendQuery();
  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;

  const handleClick = (code, name) => {
    sendQueryPayload({
      query: `${code} - ${name}`,
      inputType: "",
      data: code,
      cxpayload: null,
    });
    CloseDrawer();
  };

  return (
    <Container>
      {value === "" && (
        <h4 style={{ margin: "0", fontSize: "16px", color: "black" }}>
          {lang === "en" ? <>Popular Stations</> : <>लोकप्रिय स्टेशन</>}
        </h4>
      )}

      {trains.map((train) => {
        return (
          <Row onClick={() => handleClick(train.code, train.name)}>
            <span>{train.code}</span>
            <p>{train.name}</p>
          </Row>
        );
      })}

      {trains.length < 1 && (
        <h4>{lang === "en" ? "No Stations Found" : "कोई स्टेशन नहीं मिला"}</h4>
      )}
    </Container>
  );
};

export default Content;
