import moment from "moment";
import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { sendFeedback } from "../../../../Api/feedback";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import {
  playAudio,
  setBlink,
  stopAudio,
} from "../../../../Store/Dispatcher/behaviour";
import Dislike from "../Dislike";
import Like from "../Like";
import Container from "./styles";
import { regenerateAnswer } from "../../../../Api/sendQuery";
const rew = require("../../../../Assets/refresh.png");
const logo = require("../../../../Assets/logo.svg").default;
const speaker = require("../../../../Assets/speaker.png");

const like = require("../../../../Assets/like.png");
const dislike = require("../../../../Assets/dislike.png");

const Message = ({ id, text, sender, audioUrl, time, isGPT }) => {
  const audio = useGetReduxState().behaviour.audio;
  const [textState, setTextState] = useState(text);
  const [audioState, setAudioState] = useState(audioUrl);

  const [playing, setPlaying] = useState(audio.current === id);
  const toggle: any = () => {
    if (!playing) {
      playAudio({ id: id, url: audioState });
    } else {
      stopAudio();
    }
    setPlaying(!playing);
  };

  useEffect(() => {
    setPlaying(audio.current === id);
  }, [audio.current]);

  const [showLike, setLike] = useState(false);
  const [showDislike, setDislike] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [blinkerText, setBlinkerText] = useState("");
  const ref = useRef<HTMLParagraphElement>();
  const messages = useGetReduxState().messages;
  const messageIndex = messages.indexOf(
    messages.find((message) => message.id === id)
  );
  const [isStopped, setIsStopped] = useState(false);

  useEffect(() => {
    if (showLike) {
      setTimeout(() => {
        setLike(false);
        setFeedback(true);
        sendFeedback({ id: id, feedback: "Like", comment: "" });
      }, 2000);
    }
  }, [showLike]);

  const handleDislike = (comment) => {
    sendFeedback({ id: id, feedback: "Dislike", comment: comment });
    setDislike(false);
    setFeedback(true);
  };

  function waitForMs(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [i, setI] = useState(0);
  var t;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const letters = textState.split("");
    if (sender === "BOT" && i < letters.length && !isStopped && textState) {
      // typeSentence(textState, ref);

      setBlinkerText((txt) => txt + letters[i]);
      setBlink((txt) => txt + letters[i]);
      t = setTimeout(() => {
        setI((prev) => prev + 1);
      }, 50);
    }

    return () => {
      clearTimeout(t);
    };
  }, [i, textState]);

  // useEffect(() => {

  // }, [textState]);

  // useEffect(() => {
  //   if (blinkerText === text) alert(1);
  // }, [blinkerText, text]);

  const getReg = async () => {
    setLoading(true);

    setTextState("");
    setIsStopped(false);
    setBlink("");
    let correct = messageIndex - 1;
    if (messages[correct].sender === "BOT") correct = messageIndex - 2;
    setI(0);
    let d = await regenerateAnswer(messages[correct].text);
    setBlinkerText("");
    setTextState(d.answer);
    setAudioState(d.audio);
    setLoading(false);
  };

  const stopReg = async () => {
    setIsStopped(true);
  };

  return (
    <Container isBot={sender === "BOT"}>
      <div className="text-container">
        {sender === "BOT" && (
          <div className="avatar">
            <img src={logo} alt="" />
          </div>
        )}

        {sender === "BOT" ? (
          <div className="text" style={{ position: "relative" }}>
            <p
              dangerouslySetInnerHTML={{ __html: String(blinkerText) }}
              ref={ref}
              style={{ display: "inline" }}
            ></p>
            {blinkerText !== textState &&
              !(isStopped && messageIndex < messages.length - 1) && (
                <span className="input-cursor"></span>
              )}
            {sender === "BOT" &&
              audioState &&
              !loading &&
              (blinkerText || isStopped) && (
                <div
                  className="speaker"
                  style={{ backgroundColor: playing ? "orange" : "black" }}
                  data-audioUrl={audioState}
                  onClick={toggle}
                >
                  <img src={speaker} alt="" />
                </div>
              )}
          </div>
        ) : (
          <>
            <p className="text">{textState}</p>
          </>
        )}

        {isGPT &&
          sender === "BOT" &&
          messageIndex !== 0 &&
          !isStopped &&
          !loading &&
          blinkerText !== textState && (
            <button
              style={{
                display: "block",
                margin: "10px auto 0px",
                cursor: "pointer",
                padding: "5px 14px",
                borderRadius: "5px",
                border: "1px solid gray",
                background: "rgb(57, 57, 57)",
                color: "white",
                position: "absolute",
                bottom: "-33px",
                left: "33px",
                width: "80px",
              }}
              onClick={stopReg}
            >
              <span
                style={{
                  display: "inline-block",
                  height: "10px",
                  width: "10px",
                  background: "white",
                  marginRight: "5px",
                  borderRadius: "2px",
                }}
              ></span>{" "}
              Stop
            </button>
          )}

        {sender === "BOT" &&
          !loading &&
          (blinkerText === textState || (blinkerText && isStopped)) && (
            <div
              style={{
                left: "36px",
                bottom: "-22px",
                position: "absolute",
                right: "8px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: "8px",

                  width: "fit-content",
                  display: "block",
                }}
                className="timestamp"
              >
                {moment(time, "DD:MM:YY HH:mm").fromNow()}
              </span>
              {!feedback && (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    columnGap: "7px",
                  }}
                >
                  <img
                    style={{ width: "15px", cursor: "pointer" }}
                    src={dislike}
                    alt=""
                    onClick={() => setDislike(true)}
                  />
                  <img
                    style={{ width: "15px", cursor: "pointer" }}
                    src={like}
                    alt=""
                    onClick={() => setLike(true)}
                  />
                </span>
              )}
            </div>
          )}
        {sender === "BOT" &&
          messageIndex !== 0 &&
          isGPT &&
          (blinkerText === textState ||
            (isStopped && blinkerText) ||
            loading) && (
            <img
              src={rew}
              style={{
                position: "absolute",
                bottom: "12px",
                right: "-26px",
                width: "18px",
                cursor: "pointer",
              }}
              className={loading ? "refresh" : ""}
              onClick={getReg}
            />
          )}
      </div>
      {showDislike && (
        <Dislike
          handle={(comment) => handleDislike(comment)}
          handleCancel={() => setDislike(false)}
        />
      )}
      {showLike && <Like />}
      {/* messageIndex !== 0 && isGPT && */}
    </Container>
  );
};

export default Message;
