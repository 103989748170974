import axios from "axios";

export const getAllStations = async () => {
  try {
    const response = await axios.get(
      "https://storage.corover.ai/askdisha-irctc/stations.json",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch {
    return [];
  }
};

export const getPopularStations = async () => {
  try {
    const response = await axios.get(
      "https://storage.corover.ai/askdisha-irctc/popular.json",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch {
    return [];
  }
};
