import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Menu from "./Menu";

import Container from "./styles";
const logo = require("../../Assets/logo.svg").default;
const menu = require("../../Assets/menu.png");
const home = require("../../Assets/home.png");

const Header = () => {
  const messages = useGetReduxState().messages;
  const [open, setOpen] = useState(false);

  const handleRefresh = () => {
    window.location.reload();
  };
  const currentFull = useGetReduxState().behaviour.fullScreenComponent;

  return (
    <Container
      isSmall={
        localStorage.getItem("jwt") &&
        (!currentFull.type || currentFull.type === "COMPLAINTS")
      }
    >
      <div className="hero">
        {/* <img src={bg} alt="" className="bg" /> */}

        <img src={logo} alt="" onClick={() => handleRefresh()} />
        <div className="text">
          <h1>
            RailGPT
            <span
              style={{
                fontSize: "9px",
                fontWeight: "700",
                border: "1px solid",
                padding: "0px 4px",
                borderRadius: "3px",
                position:
                  localStorage.getItem("jwt") &&
                  (!currentFull.type || currentFull.type === "COMPLAINTS")
                    ? "static"
                    : "absolute",
                top: "15px",
                right: "15px",
              }}
            >
              BETA
            </span>
          </h1>
          <p>Assisting your journey with ease and efficiency.</p>
        </div>
        {localStorage.getItem("jwt") &&
          (!currentFull.type || currentFull.type === "COMPLAINTS") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "flex-end",
                flexShrink: "1",
                flexGrow: "0",
              }}
            >
              <img
                src={home}
                style={{ width: "22px", cursor: "pointer" }}
                className="home"
                alt=""
                onClick={handleRefresh}
              />
              <img
                src={menu}
                style={{ width: "22px", cursor: "pointer", marginTop: "5px" }}
                className={"menu"}
                alt=""
                onClick={() => setOpen(true)}
              />
            </div>
          )}
      </div>
      {open && <Menu handleClose={() => setOpen(false)} />}
    </Container>
  );
};

export default Header;
