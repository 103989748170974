import React, { useEffect, useState } from "react";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import Drawer from "../../../../../UI/Drawer";
import Microphone from "../../../Microphone";
import Content from "./Content";
import Header from "./Header";

const TrainPicker = ({ handleClick, handleClose }) => {
  const pop = useGetReduxState().behaviour.popularStations;
  const all = useGetReduxState().behaviour.allStations;
  const [trains, setTrains] = useState(pop);
  const [mic, setMic] = useState(false);
  const [key, setKey] = useState("");
  const toggleMic = () => {
    setMic((prev) => !prev);
  };

  const audio = useGetReduxState().behaviour.audio;
  const currentStep = useGetReduxState().behaviour.currentStep;
  const inputMode = useGetReduxState().behaviour.input_mode;
  const nextStep = useGetReduxState().behaviour.nextContext;
  const confirmD = useGetReduxState().complaint.show;

  const filterTrains = (key) => {
    setKey(key);
    if (key === "") {
      setTrains(pop);
      return;
    }

    let sorted1 = [];
    let sorted2 = [];
    let remaining = [];

    if (key.length < 5) {
      sorted1 = all.filter((train) =>
        train.code.toLowerCase().startsWith(key.toLowerCase())
      );

      sorted2 = all.filter((train) =>
        train.name.toLowerCase().startsWith(key.toLowerCase())
      );

      remaining = all.filter(
        (train) =>
          !(
            train.name.toLowerCase().startsWith(key.toLowerCase()) ||
            train.code.toLowerCase().startsWith(key.toLowerCase())
          )
      );
    } else {
      sorted2 = all.filter((train) =>
        train.code.toLowerCase().startsWith(key.toLowerCase())
      );

      sorted1 = all.filter((train) =>
        train.name.toLowerCase().startsWith(key.toLowerCase())
      );

      remaining = all.filter(
        (train) =>
          !(
            train.name.toLowerCase().startsWith(key.toLowerCase()) ||
            train.code.toLowerCase().startsWith(key.toLowerCase())
          )
      );
    }

    let t = remaining.filter(
      (train) =>
        train.name.toLowerCase().includes(key.toLowerCase()) ||
        train.code.toLowerCase().includes(key.toLowerCase())
    );

    setTrains([...sorted1, ...sorted2, ...t]);
  };

  useEffect(() => {
    if (
      audio.url === "" &&
      inputMode === "VOICE" &&
      currentStep &&
      currentStep !== "messsage" &&
      currentStep.length > 2 &&
      nextStep &&
      !confirmD
    ) {
      toggleMic();
    }
  }, [audio.current]);

  return (
    <Drawer
      header={
        <Header value={key} filterTrains={filterTrains} toggleMic={toggleMic} />
      }
      content={
        <React.Fragment>
          <Content trains={trains} hide={handleClick} value={key} />
          {mic && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: "white",
              }}
            >
              <Microphone
                handleVoice={(value) => {
                  filterTrains(value);
                  setMic(false);
                }}
                handleVoiceOptions={({ label, value }) => {
                  filterTrains(value);
                  setMic(false);
                }}
                handleClose={() => setMic(false)}
                useEnglish={true}
              />
            </div>
          )}
        </React.Fragment>
      }
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={false}
      fullHeight={true}
      showHeaderSeperator={false}
    />
  );
};

export default TrainPicker;
