import React from "react";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";

const Header = () => {
  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;

  if (lang === "hi") return <div>अपनी तिथि चुनें</div>;

  return <div>Select your date</div>;
};

export default Header;
