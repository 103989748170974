import axios from "axios";
let cancelToken;

export const getComplaints = async (phone) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  let payload = {
    mobileNumber: phone,
  };

  try {
    const response = await axios.post(
      `/gptAPI/railGPT/getComplaints`,
      payload,
      {
        cancelToken: cancelToken.token,
      }
    );

    return response.data;
  } catch (e) {
    return { error: true, status: e.response?.status };
  }
};
