import React from "react";
import {
  CloseAllDrawers,
  CloseDrawer,
  queryFire,
} from "../../../Helpers/EventEmitter";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import useSendQuery from "../../../Hooks/useSendQuery";
import { setNextContext } from "../../../Store/Dispatcher/behaviour";
import Buttons from "../../../Templates/Buttons";
import Drawer from "../../../UI/Drawer";

const langTrxt = {
  en: {
    "1": "Register a complaint",
    "2": "Track my concern",
    // "3": "Spot my train",
  },
  hi: {
    "1": "शिकायत दर्ज करें",
    "2": "मेरी शिकायत ट्रैक करें",
    // "3": "मेरी ट्रेन का पता लगाएं",
  },
};

const Content = () => {
  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;
  const currentStep = useGetReduxState().behaviour.currentStep;
  const { sendQueryPayload, refreshQuery } = useSendQuery();
  return (
    <div style={{ marginTop: "-20px", marginBottom: "16px" }}>
      <Buttons
        header={""}
        buttons={[
          {
            text: "📣 " + langTrxt[lang][1],
            type: "default",
            handleClick: () => {
              setNextContext(null);
              // sendQueryPayload({
              //   query: langTrxt[lang][1],
              //   inputType: "TEXT",
              //   data: null,
              //   cxpayload: null,
              // });

              queryFire(langTrxt[lang][1]);
              CloseAllDrawers();
            },
            icon: "",
          },
          {
            text: "⏱️ " + langTrxt[lang][2],
            type: "default",
            handleClick: () => {
              setNextContext(null);
              // sendQueryPayload({
              //   query: langTrxt[lang][2],
              //   inputType: "TEXT",
              //   data: null,
              //   cxpayload: null,
              // });
              queryFire(langTrxt[lang][2]);
              CloseAllDrawers();
            },
            icon: "",
          },
        ]}
      />
    </div>
  );
};

const Menu = ({ handleClose }) => {
  const lang = useGetReduxState().behaviour.lang;

  return (
    <Drawer
      header={
        <div>
          {lang === "en"
            ? "What are you looking for?"
            : "आप क्या ढूंढ रहे हैं?"}
        </div>
      }
      content={<Content />}
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={true}
      fullHeight={false}
      showHeaderSeperator={false}
    />
  );
};

export default Menu;
