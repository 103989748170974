import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { sendQuery } from "../../../Api/sendQuery";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import useSendQuery from "../../../Hooks/useSendQuery";
import { toggleMic } from "../../../Store/Dispatcher/behaviour";
import LanguageMenu from "./TextAndMic/LanguageMenu";
import Container from "./styles";
import TextAndMic from "./TextAndMic";
import SelectTrain from "./SelectTrain";
import SelectDateTime from "./SelectDateTime";
import SelectBox from "./SelectBox";
import { addCustomResponseMessage } from "../../../Store/Dispatcher/messages";
import Buttons from "../../../Templates/Buttons";
import moment from "moment";
import SelectDate from "./SelectDate";
import SelectStation from "./SelectStation";

const Sender = () => {
  const currentStep = useGetReduxState().behaviour.currentStep;
  const { sendQueryPayload } = useSendQuery();
  const nextContext = useGetReduxState().behaviour.nextContext;
  const lang: "hi" | "en" = useGetReduxState().behaviour.lang;
  const loader = useGetReduxState().behaviour.showMessageLoader;

  useEffect(() => {
    if (currentStep === "complainDate")
      addCustomResponseMessage({
        component: (
          <Buttons
            header={""}
            buttons={[
              {
                text: lang === "en" ? "Today (Now)" : "आज (अभी)",
                type: "default",
                icon: "",
                handleClick: () => {
                  sendQueryPayload({
                    query: moment().format("DD MMM, YYYY - HH:mm"),
                    inputType: "",
                    data: moment().format("DD/MM/YYYY HH:mm"),
                    cxpayload: null,
                  });
                },
              },
            ]}
          />
        ),
        showAvatar: false,
      });
  }, [currentStep]);

  if (loader) return <></>;

  if (currentStep === "commodity")
    return (
      <Container>
        <SelectBox type={currentStep} />
      </Container>
    );

  if (currentStep === "complainDate")
    return (
      <Container>
        <SelectDateTime />
      </Container>
    );

  if (currentStep === "selectTrain")
    return (
      <Container>
        <SelectTrain />
      </Container>
    );

  if (currentStep === "trainDate")
    return (
      <Container>
        <SelectDate />
      </Container>
    );

  if (currentStep === "station")
    return (
      <Container>
        <SelectStation />
      </Container>
    );

  return (
    <Container>
      <TextAndMic />
    </Container>
  );
};

export default Sender;
