import React, { useState } from "react";
import {
  setFullScreen,
  toggleLoader,
} from "../../../../Store/Dispatcher/behaviour";
import {
  addCustomResponseMessage,
  addResponseMessage,
} from "../../../../Store/Dispatcher/messages";
import ComplaintInfo from "../../../../Templates/ComplaintInfo";
import Container from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import moment from "moment";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
const icon = require("../../../../Assets/refresh.png");

const langText = {
  en: {
    view: "View Details",
    hide: "Hide Details",
  },
  hi: {
    view: "विवरण देखें",
    hide: "विवरण हटाएं",
  },
};

const Complaints = ({ complaints, handleRefresh, loading }) => {
  const [expanded, setExpanded] = useState(-1);
  const lang = useGetReduxState().behaviour.lang;
  const viewDetails = (ind) => {
    // setFullScreen({ type: null, component: null });
    // toggleLoader();
    // setTimeout(() => {
    //   toggleLoader();
    //   addResponseMessage({
    //     audioUrl: "",
    //     text: "Here are your complaint details: ",
    //     showAvatar: false,
    //     id: "1",
    //   });
    //   addCustomResponseMessage({
    //     component: (
    //       <ComplaintInfo
    //         complaintType={complaintType}
    //         referenceId={referenceId}
    //         incidentDate={incidentDate}
    //         status={status}
    //         remark={remark}
    //         subComplaintType={subComplaintType}
    //       />
    //     ),
    //     showAvatar: true,
    //   });
    // }, 1000);
  };

  const getColor = (status) => {
    let c = status.toLowerCase();

    if (c === "registered") return "#fc9b94";

    if (c === "closed") return "#60c964";

    return "gray";
  };

  return (
    <Container>
      {/* <span
        className="cross"
        onClick={() => setFullScreen({ type: null, component: null })}
      >
        X
      </span> */}

      <h3
        style={{
          fontSize: "12px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "-3px",
          marginTop: "10px",
        }}
      >
        {lang === "en" ? <>Your Complaints</> : <>आपकी शिकायतें</>}

        <img
          style={{ width: "14px", marginLeft: "10px", cursor: "pointer" }}
          src={icon}
          alt=""
          onClick={handleRefresh}
          className={loading ? "refresh" : ""}
        />
      </h3>

      <ScrollContainer
        style={{
          padding: "10px 2px",
          display: "flex",
          cursor: "-webkit-grab",
          opacity: loading ? 0.4 : 1,
          alignItems: "flex-start",
        }}
      >
        {complaints
          .map((complaint) => {
            return {
              complaintType: complaint.complaint,
              referenceId: complaint.complaintReferenceNo,
              incidentDate: complaint.incidentDate,
              status: complaint.status,
              remark: complaint.remark,
              subComplaintType: complaint.subComplaint,
            };
          })
          .map((complaint, index) => (
            <div className="complaint">
              <div className="first-row">
                <div className="ref-id">
                  {" "}
                  <span>Ref ID: </span>
                  <p>{complaint.referenceId}</p>
                </div>

                <p
                  className="status"
                  style={{
                    color: "white",
                    borderColor: getColor(complaint.status),
                    backgroundColor: getColor(complaint.status),
                  }}
                >
                  {complaint.status}
                </p>
              </div>

              <div className="type">
                {" "}
                <span className="label">Type: </span>
                <div>
                  <p>Medical Assistance</p>
                  <span>Medical Assistance</span>
                </div>
              </div>
              <div className="second-row">
                <p className="date">
                  {moment(complaint.incidentDate, "DD/MM/YYYY hh:mm").format(
                    "DD MMM, YYYY | hh:mm"
                  )}
                </p>

                <button
                  onClick={() => {
                    if (expanded === index) setExpanded(-1);
                    else setExpanded(index);
                  }}
                >
                  {expanded === index
                    ? langText[lang].hide
                    : langText[lang].view}
                </button>
              </div>
              {expanded === index && (
                <div className="expanded">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      fontSize: "14px",
                      columnGap: "6px",
                    }}
                    className="first"
                  >
                    <span>Type:</span>
                    <p>{complaint.complaintType}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginTop: "10px",
                      fontSize: "14px",
                      columnGap: "6px",
                    }}
                    className="first"
                  >
                    <span style={{ flexShrink: 0 }}>Sub-Type:</span>
                    <p>{complaint.subComplaintType}</p>
                  </div>
                  {complaint.status?.toLowerCase() === "closed" && (
                    <div
                      className="remarak"
                      style={{
                        fontSize: "12px",
                        color: "gray",
                        marginTop: "10px",
                      }}
                    >
                      {complaint.remark}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
      </ScrollContainer>
    </Container>
  );
};

export default Complaints;
