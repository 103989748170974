import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;

  .first {
    flex-shrink: 0;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px;
      background-color: aliceblue;

      p {
        font-size: 16px;
      }

      button {
        font-size: 16px;
        color: cornflowerblue;
        font-weight: 500;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .stations {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px;

      div {
        span {
          font-size: 12px;
          font-weight: 500;
          color: black;
        }

        p {
          font-size: 14px;
        }

        h5 {
          font-weight: 500;
        }
      }
    }

    .delay {
      padding-left: 15px;
      padding-bottom: 15px;
    }

    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px;
      background-color: aliceblue;

      p {
        font-size: 14px;
      }
    }
  }

  .tree {
    flex-grow: 1;
    overflow-y: auto;
    padding: 14px;

    .row {
      height: 60px;
      max-height: 60px;
      overflow: hidden;
      font-size: 14px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .station {
        flex-shrink: 0;
        width: 45px;
        p {
        }

        span {
          font-size: 10px;
        }
      }

      .line {
        height: 100%;
        position: relative;
        margin: 0px 20px;
        .upper {
          height: 50%;
          width: 0;
          border-left: 2px solid black;
        }

        .lower {
          height: 50%;
          width: 0;
          border-left: 2px solid black;
        }

        .dot {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: black;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 1px solid;
        }
      }

      .times {
        width: 100%;
        .name {
          font-size: 10px;
        }

        .times-delays {
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            p {
            }
            span {
            }
          }
        }
      }
    }
  }

  .refresh {
    flex-shrink: 0;
    width: fit-content;
    margin: 12px auto;
    font-size: 18px;
    padding: 8px 22px;
    border-radius: 6px;
    border: none;
    background-color: #edb01b;
    color: white;
    font-weight: 500;
    cursor: pointer;
  }
`;

export default Container;
