import styled from "styled-components";

const Container = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  margin: 34px auto;
  margin-bottom: 0px;

  p {
    font-size: 10px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: gray;
    margin-bottom: 8px;
  }
`;

export default Container;
