import React, { useEffect } from "react";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Container from "./styles";
import getDetails from "./utils";

const Row = ({ position, station, active }) => {
  return (
    <div
      style={{ opacity: position === -1 ? 0.3 : 1 }}
      className={`${active ? "row active" : "row inactive"}`}
    >
      <div className="station">
        <p>{station.code}</p>
        <span>{station.distance} km</span>
      </div>
      <div className="line">
        <div className="upper"></div>
        <div
          className="dot"
          style={{
            backgroundColor: active ? "green" : "white",
            borderColor: active ? "green" : "black",
          }}
        ></div>
        <div className="lower"></div>
      </div>
      <div className="times">
        <p className="name">{station.name}</p>
        <div className="times-delays">
          <div style={{ width: "39px" }}>
            <p>{station.scheduledArrival}</p>
            <span
              style={{
                color:
                  station.scheduledArrival === station.actualArrival
                    ? "green"
                    : "red",
              }}
            >
              {station.actualArrival}
            </span>
          </div>
          <div style={{ width: "39px" }}>
            <p>{station.scheduledDeparture}</p>
            <span
              style={{
                color:
                  station.scheduledDeparture === station.actualDeparture
                    ? "green"
                    : "red",
              }}
            >
              {station.actualDeparture}
            </span>
          </div>
          <div style={{ width: "55px" }}>
            <p>{station.pfOrHalt}</p>
            <span
              style={{ color: station.delay === "On Time" ? "green" : "red" }}
            >
              {station.delay}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SpotTrain = ({ hide, data }) => {
  useEffect(() => {
    const sc = document.querySelector(".row.active");

    setTimeout(() => {
      if (sc) sc.scrollIntoView();
    }, 1000);
  }, []);

  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;

  const langText = {
    en: {
      current: "Current Station",
      next: "Next Station",
      na: "Not Available",
      station: "Station",
      arrival: "Arrival",
      departure: "Departure",
      pf: "PF/Halt",
      refresh: "Refresh",
      hide: "Hide",
      journey: "Journey",
      to: "To",
    },
    hi: {
      current: "वर्तमान स्टेशन",
      next: "अगला स्टेशन",
      na: "उपलब्ध नहीं है",
      station: "स्टेशन",
      arrival: "आगमन",
      departure: "प्रस्थान",
      pf: "PF/पड़ाव",
      refresh: "रिफ्रेश",
      hide: "बैक",
      journey: "यात्रा",
      to: "से",
    },
  };

  const {
    trainName,
    trainNumber,
    source,
    sourceLabel,
    destination,
    destinationLabel,
    stations,
  } = getDetails(data);

  const currentStation = stations.filter(
    (station) => station.position === 1
  )[0];

  const getNextStation = () => {
    // let indOfCurrent = stations.findIndex(
    //   (station) => station.code === currentStation.code
    // );

    let index = stations.indexOf(currentStation);
    return stations[index + 1] ? stations[index + 1] : currentStation;
  };

  return (
    <Container>
      <div className="first">
        <div className="header">
          <p>
            {trainNumber} - {trainName}
          </p>
          <button onClick={hide}>{langText[lang].hide}</button>
        </div>

        <div className="stations">
          <div>
            <span>{langText[lang].current}</span>
            <p>
              {currentStation?.name ? currentStation?.name : langText[lang].na}
            </p>
            <h5>({currentStation?.code ? currentStation?.code : "N/A"})</h5>
          </div>
          <div>
            <span>{langText[lang].next}</span>
            <p>
              {currentStation?.code ? getNextStation().name : langText[lang].na}
            </p>
            <h5>({currentStation?.code ? getNextStation().code : "N/A"})</h5>
          </div>
        </div>
        <h5 className="delay">
          {langText[lang].journey}: {source} {langText[lang].to} {destination}
        </h5>

        <div className="table-header">
          <p>{langText[lang].station}</p>
          <p>{langText[lang].arrival}</p>
          <p>{langText[lang].departure}</p>
          <p>{langText[lang].pf}</p>
        </div>
      </div>

      <div className="tree">
        {stations.map((station, index) => (
          <Row
            station={station}
            active={station.position === 1}
            position={
              stations.findIndex((s) => s.code === currentStation?.code) > index
                ? -1
                : 1
            }
          />
        ))}
      </div>

      {/* <button className="refresh">{langText[lang].refresh}</button> */}
    </Container>
  );
};

export default SpotTrain;
