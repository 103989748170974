import React, { useEffect, useState } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import DateTimePicker from "./DateTimePicker";

const SelectDateTime = () => {
  const [open, setOpen] = useState(false);
  const lang: "en" | "hi" = useGetReduxState().behaviour.lang;

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      <div className="select-train-input" onClick={() => setOpen(true)}>
        <input
          readOnly
          type="text"
          placeholder={
            lang === "en"
              ? "Please select date and time"
              : "कृपया तिथि और समय चुनें"
          }
        />
      </div>

      {open && <DateTimePicker handleClose={() => setOpen(false)} />}
    </React.Fragment>
  );
};

export default SelectDateTime;
