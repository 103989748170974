const initialData = {
  show: false,
  isParsed: false,
  allTypes: [],
  allSubTypes: [],
  parseData: {
    complaintType: {
      label: "",
      key: "",
    },
    subType: {
      label: "",
      key: "",
    },
    related: {
      label: "",
      key: "",
    },
    description: "",
    trainNo: "",
    pnr: "",
  },
};

const getData = (data) => {
  let temp = initialData;
  temp.show = true;
  temp.isParsed = data.renderData?.type?.value ? true : false;
  temp.allTypes = data.data.types.map((elem) => {
    return {
      label: elem.value,
      key: elem.key,
    };
  });

  temp.allSubTypes = data.data.subTypes;

  if (data.renderData?.type?.value) {
    temp.parseData.complaintType = {
      label: data.renderData.type.value,
      key: data.renderData.type.key,
    };

    temp.parseData.subType = {
      label: data.renderData.subType.value,
      key: data.renderData.subType.key,
    };

    temp.parseData.related = {
      label: data.renderData.name.key,
      key: data.renderData.name.value,
    };
  }

  temp.parseData.description = data.renderData.description;
  temp.parseData.pnr = data.renderData.pnr;
  temp.parseData.trainNo = data.renderData.trainNo;
  return temp;
};

const reducer = (allData = initialData, action) => {
  switch (action.type) {
    case "SET_COMPLAINT_DATA":
      return getData(action.data);
    //   return { ...behaviour, showMessageLoader: !behaviour.showMessageLoader };
    case "RESET_COMPLAINT_DATA":
      return {
        show: false,
        isParsed: false,
        allTypes: [],
        allSubTypes: [],
        parseData: {
          complaintType: {
            label: "",
            key: "",
          },
          subType: {
            label: "",
            key: "",
          },
          related: {
            label: "",
            key: "",
          },
          description: "",
          trainNo: "",
          pnr: "",
        },
      };
    default:
      return allData;
  }
};

export default reducer;
