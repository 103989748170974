import moment from "moment";
import React, { useEffect, useState } from "react";
import { sendQuery as api } from "../Api/sendQuery";
import SpotTrain from "../Chatbot/SpotTrain";
import {
  setCurrentStep,
  setFullScreen,
  setOptions,
  toggleMic,
  setAllTrains,
} from "../Store/Dispatcher/behaviour";
import {
  playAudio,
  setInputType,
  setNextContext,
  toggleLoader,
} from "../Store/Dispatcher/behaviour";
import {
  resetComplaintData,
  setComplaintData,
} from "../Store/Dispatcher/complaints";
import {
  addCustomResponseMessage,
  addResponseMessage,
  addUserMessage,
} from "../Store/Dispatcher/messages";
import Buttons from "../Templates/Buttons";
import ComplaintInfo from "../Templates/ComplaintInfo";
import useGetReduxState from "./useGetReduxState";
import { flushSync } from "react-dom";
import EventEmitter from "../Helpers/EventEmitter";

interface QueryPayload {
  inputType: "TEXT" | "VOICE" | "";
  query: string;
  data: any | undefined;
  cxpayload: any;
}

const validMobile = (text) => {
  const regex = /^[0]?[789]\d{9}$/;

  return regex.test(text);
};

const useSendQuery = () => {
  const inputTypeInRedux = useGetReduxState().behaviour.input_mode;
  let input_mode: "TEXT" | "VOICE" = "TEXT";
  const empty: QueryPayload = {
    query: "",
    inputType: inputTypeInRedux,
    data: null,
    cxpayload: null,
  };
  const [queryPayload, sendQueryPayload] = useState<QueryPayload>(empty);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    console.log(queryPayload);
  }, [queryPayload]);

  const nextContext = useGetReduxState().behaviour.nextContext;
  const langCode = useGetReduxState().behaviour.lang;
  const currentStep = useGetReduxState().behaviour.currentStep;
  const mic = useGetReduxState().behaviour.mic;
  //in case user sends same query again
  const refreshQuery = () => {
    sendQueryPayload((prev) => ({ ...prev, query: "", cxpayload: null }));
  };

  const send = async ({ showUserMessage }) => {
    resetComplaintData();
    if (mic) toggleMic();
    if (queryPayload.inputType) {
      input_mode = queryPayload.inputType;
    } else input_mode = inputTypeInRedux;

    setInputType(input_mode);
    // let flowStep = getFlowStep({
    //   nextContext: next_context || "",
    //   options: undefined,
    //   context: undefined,
    //   isRefund: false,
    // });

    // setFullScreen({ type: null, component: null });

    if (showUserMessage) {
      //   if (flowStep === "SELECT_TRAIN")
      //     addCustomResponseMessage({
      //       component: <SelectedTrain />,
      //       showAvatar: false,
      //     });
      //   else
      addUserMessage({ text: queryPayload.query });
    }

    if (currentStep) {
      if (currentStep === "mobileNumber" && !validMobile(queryPayload.query)) {
        addResponseMessage({
          text:
            langCode === "en"
              ? "Please provide a valid mobile number"
              : "कृपया एक मान्य मोबाइल नंबर दर्ज करें",
          audioUrl: "",
          showAvatar: true,
          id: Date().toString(),
        });
        return;
      }

      if (
        nextContext?.includes("train,3") &&
        queryPayload.query.length !== 10
      ) {
        let validationMsg =
          sessionStorage.getItem("typeOfNum") === "UTS"
            ? langCode === "en"
              ? "Please provide a valid UTS number"
              : "कृपया मान्य UTS नंबर दर्ज करें"
            : langCode === "en"
            ? "Please provide a valid PNR number"
            : "कृपया मान्य PNR नंबर दर्ज करें";
        addResponseMessage({
          text: validationMsg,
          audioUrl: "",
          showAvatar: true,
          id: Date().toString(),
        });
        return;
      }
    }

    toggleLoader();

    if (queryPayload.query.toLowerCase().includes("pink city")) {
      let t = Date().toString();
      setTimeout(() => {
        addResponseMessage({
          text: `Jaipur, also known as the "Pink City" of India, is a popular tourist destination known for its rich history, vibrant culture, and stunning architecture. Some of the top travel destinations in Jaipur include the Amer Fort, Hawa Mahal, City Palace, Jantar Mantar, Jal Mahal, and the city's vibrant markets and bazaars. These destinations offer visitors the opportunity to explore the grandeur and opulence of Rajasthani royalty, witness stunning architecture, and shop for traditional items. With its vibrant culture and beautiful attractions, Jaipur is a must-visit destination for anyone traveling to India.`,
          audioUrl:
            "https://storage.corover.ai/chatbot-audio-bucket-aws/d33af562-b12e-44c6-ba94-3989eba60513_en.wav",
          showAvatar: true,
          id: t,
        });
        toggleLoader();

        playAudio({
          id: t,
          url: "https://storage.corover.ai/chatbot-audio-bucket-aws/d33af562-b12e-44c6-ba94-3989eba60513_en.wav",
        });
      }, 1500);

      return;
    }

    // setCurrentStep(flowStep);

    // let cxpayload = getCxpayload(
    //   flowStep,
    //   queryPayload.query,
    //   queryPayload.data
    // );

    let q = queryPayload.data ? queryPayload.data : queryPayload.query;
    const data = await api({
      query: q,
      cxpayload: queryPayload.cxpayload ? queryPayload.cxpayload : null,
      //   next_context: next_context,
      //   langCode: langCode,
      //   cxpayload: cxpayload,
      //   isFallback: conf.isFallback,
    });

    // if (data.error && data.status) {
    //   if (data.status === 404) {
    //     addCustomResponseMessage({
    //       component: <Fallback />,
    //       showAvatar: false,
    //     });
    //   } else if (data.status === 401) {
    //     addCustomResponseMessage({
    //       component: (
    //         <Alert
    //           error={"Session Time Out!"}
    //           suberror={
    //             "It looks like your session has been timed out. Click OK to start a new session."
    //           }
    //           handleClose={() => window.location.reload()}
    //         />
    //       ),
    //       showAvatar: false,
    //     });
    //   } else {
    //     addCustomResponseMessage({
    //       component: (
    //         <Fallback500
    //           handleTry={() => {
    //             deleteLastNmessages(2);
    //             send({ showUserMessage: true });
    //             changeCallback();
    //           }}
    //           handleHome={() => {
    //             goToHome();
    //           }}
    //         />
    //       ),
    //       showAvatar: false,
    //     });
    //   }

    //   toggleLoader();

    //   return;
    // }

    // const flowName2 = getFlowName({
    //   nextContext: data.next_context || "",
    //   options: data.options,
    //   context: data.context,
    //   isRefund: data.isRefund,
    // });

    // if (
    //   [
    //     "PNR_FLOW",
    //     "CANCEL_FLOW",
    //     "BOOKING_FLOW",
    //     "BOARDING_FLOW",
    //     "TDR_FLOW",
    //     "BOOKING_HISTORY_FLOW",
    //   ].includes(flowName2)
    // ) {
    //   if (
    //     disabledSettings.other &&
    //     [
    //       "PNR_FLOW",
    //       "CANCEL_FLOW",
    //       "BOARDING_FLOW",
    //       "TDR_FLOW",
    //       "BOOKING_HISTORY_FLOW",
    //     ].includes(flowName2)
    //   ) {
    //     toggleLoader();
    //     addCustomResponseMessage({
    //       component: <Maintainance isFromIRCTC={false} message={""} />,
    //       showAvatar: false,
    //     });
    //     return;
    //   }

    //   if (!disabledSettings.booking && "BOOKING_FLOW" === flowName2) {
    //     toggleLoader();
    //     addCustomResponseMessage({
    //       component: <Maintainance isFromIRCTC={false} message={""} />,
    //       showAvatar: false,
    //     });
    //     return;
    //   }

    //   if (isBlockTime().isBlock) {
    //     toggleLoader();
    //     addCustomResponseMessage({
    //       component: (
    //         <Maintainance isFromIRCTC={true} message={isBlockTime().message} />
    //       ),
    //       showAvatar: false,
    //     });

    //     return;
    //   }
    // }

    // if (data.apiReference && data.apiReference === "otp-send") {
    //   // showLogin();

    //   setFullScreen({
    //     type: "LOGIN",
    //     component: (
    //       <Login
    //         handleLogin={() => {
    //           send({ showUserMessage: false });
    //           changeCallback();
    //         }}
    //         handleCloseDrawer={() => {
    //           deleteLastNmessages(1);
    //         }}
    //       />
    //     ),
    //   });

    //   toggleLoader();
    //   return;
    // }

    // if (data.next_context === "ade4a7db-d819-417d-832a-259307fd94c7,9")
    //   next_context("ade4a7db-d819-417d-832a-259307fd94c7,10");
    // else

    setNextContext(data.next_context || "");

    // if (data.renderTemplate || data.options || data.data) {
    //   toggleLoader();
    //   //recursively check
    //   if (data.options) {
    //     addResponseMessage({
    //       text: data.answer,
    //       audioUrl: data.audio,
    //       showAvatar: true,
    //       id: data.answerId,
    //     });

    //     toggleLoader();

    //     setTimeout(() => {
    //       toggleLoader();
    //       const CustomComponent = getTemplate("refundOptions");

    //       addCustomResponseMessage({
    //         component: getComponent(CustomComponent, null),
    //         showAvatar: false,
    //       });
    //     }, 1500);
    //   } else if (data.data?.newBoardingPoint) {
    //     addResponseMessage({
    //       text: data.answer,
    //       audioUrl: data.audio,
    //       showAvatar: true,
    //       id: data.answerId,
    //     });

    //     toggleLoader();

    //     setTimeout(() => {
    //       toggleLoader();

    //       addCustomResponseMessage({
    //         component: <BoardingChange data={data.data} />,
    //         showAvatar: false,
    //       });
    //     }, 1500);
    //   } else if (
    //     data.renderTemplate.data &&
    //     !data.renderTemplate.data.errorMessage
    //   ) {
    //     addResponseMessage({
    //       text: data.answer,
    //       audioUrl: data.audio,
    //       showAvatar: true,
    //       id: data.answerId,
    //     });

    //     toggleLoader();

    //     //match renderTemplate

    //     setTimeout(() => {
    //       toggleLoader();
    //       const CustomComponent = getTemplate(data.renderTemplate.templateName);

    //       addCustomResponseMessage({
    //         component: getComponent(CustomComponent, data.renderTemplate.data),
    //         showAvatar: false,
    //       });
    //     }, 1500);
    //   } else if (data.renderTemplate.showTrain) {
    //     addResponseMessage({
    //       text: data.answer,
    //       audioUrl: data.audio,
    //       showAvatar: true,
    //       id: data.answerId,
    //     });

    //     setFullScreen({
    //       type: "TRAINS",
    //       component: <Trains />,
    //     });
    //   } else {
    //     addResponseMessage({
    //       text: data.renderTemplate?.data?.errorMessage,
    //       audioUrl: data.audio,
    //       showAvatar: true,
    //       id: data.answerId,
    //     });
    //   }
    // } else {
    //   toggleLoader();
    //   addResponseMessage({
    //     text: data.answer,
    //     audioUrl: data.audio,
    //     showAvatar: true,
    //     id: data.answerId,
    //   });
    // }

    toggleLoader();
    addResponseMessage({
      text: data.answer,
      audioUrl: data.audio,
      showAvatar: true,
      id: data.answerId,
      isGPT: data.isGPT,
    });

    if (input_mode === "VOICE" && data.audio) {
      playAudio({
        id: data.answerId,
        url: data.audio,
      });
    }

    if (data.render) {
      if (data.render === "complainInfo") {
        let d = data.data;

        addCustomResponseMessage({
          component: <ComplaintInfo data={d} />,
          showAvatar: false,
        });
      }

      if (data.render === "trainInfo") {
        addCustomResponseMessage({
          component: (
            <Buttons
              header={""}
              buttons={[
                {
                  text:
                    langCode === "en"
                      ? "Track my train"
                      : "मेरी ट्रेन को ट्रैक करें",
                  type: "default",
                  icon: "",
                  handleClick: () => {
                    setFullScreen({
                      type: "SPOT_TRAIN",
                      component: (
                        <SpotTrain
                          hide={() => {
                            setFullScreen({ type: null, component: null });
                          }}
                          data={data.data}
                        />
                      ),
                    });
                  },
                },
              ]}
            />
          ),
          showAvatar: false,
        });
      }
    }

    setCurrentStep(data.render || "");

    if (data.options && data.render === "complainName") {
      let options = data.options.map((option) => {
        return {
          text: option.label,
          type: "default",
          icon: "",
          handleClick: () => {
            let c = {
              query: option.label,
              inputType: queryPayload.inputType,
              data: option.value,
              cxpayload: null,
            };
            console.log("F", data.render, c, queryPayload);

            sendQueryPayload({ ...JSON.parse(JSON.stringify(c)) });
          },
        };
      });

      console.log(options);
      addCustomResponseMessage({
        component: (
          <Buttons header={""} buttons={options} render={data.render} />
        ),
        showAvatar: false,
      });
    }

    if (data.options) {
      setOptions(data.options);
    }

    if (data.render === "complainDetails") {
      setComplaintData(data);
    }

    if (data.render === "selectTrain") {
      setAllTrains(data.data);
    }

    if (data.render === "commodity") {
      setOptions(data.data);
    }
  };

  useEffect(() => {
    if (queryPayload.query) {
      send({ showUserMessage: true });
      refreshQuery();
    }
  }, [queryPayload.query]);

  return { sendQueryPayload, refreshQuery };
};

export default useSendQuery;
