import React, { useEffect, useState } from "react";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import Drawer from "../../../../../UI/Drawer";
import Microphone from "../../../Microphone";
import Content from "./Content";
import Header from "./Header";

const TrainPicker = ({ handleClick, handleClose }) => {
  const alltrains = useGetReduxState().behaviour.allTrains.map((train) => {
    return {
      name: train.label.split(" [")[0],
      number: train.value,
    };
  });

  const [trains, setTrains] = useState(alltrains);
  const [mic, setMic] = useState(false);
  const [key, setKey] = useState("");

  const audio = useGetReduxState().behaviour.audio;
  const currentStep = useGetReduxState().behaviour.currentStep;
  const inputMode = useGetReduxState().behaviour.input_mode;
  const nextStep = useGetReduxState().behaviour.nextContext;
  const confirmD = useGetReduxState().complaint.show;

  const toggleMic = () => {
    setMic((prev) => !prev);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (
      audio.url === "" &&
      inputMode === "VOICE" &&
      currentStep &&
      currentStep !== "messsage" &&
      currentStep.length > 2 &&
      nextStep &&
      !confirmD
    ) {
      toggleMic();
    }
  }, [audio.current]);

  const filterTrains = (key) => {
    setKey(key);
    if (key === "") {
      setTrains(alltrains);
      return;
    }

    let sortedTrains = [];
    let remaining = [];
    if (!isNaN(parseInt(key))) {
      sortedTrains = alltrains.filter((train) =>
        train.number.toLowerCase().startsWith(key.toLowerCase())
      );

      remaining = alltrains.filter(
        (train) => !train.number.toLowerCase().startsWith(key.toLowerCase())
      );
    } else {
      sortedTrains = alltrains.filter((train) =>
        train.name.toLowerCase().startsWith(key.toLowerCase())
      );

      remaining = alltrains.filter(
        (train) => !train.name.toLowerCase().startsWith(key.toLowerCase())
      );
    }

    let t = remaining.filter(
      (train) =>
        train.name.toLowerCase().includes(key.toLowerCase()) ||
        train.number.toLowerCase().includes(key.toLowerCase())
    );
    setTrains([...sortedTrains, ...t]);
  };

  return (
    <Drawer
      header={
        <Header value={key} filterTrains={filterTrains} toggleMic={toggleMic} />
      }
      content={
        <React.Fragment>
          <Content trains={trains.slice(0, 50)} hide={handleClick} />
          {mic && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                background: "white",
              }}
            >
              <Microphone
                handleVoice={(value) => {
                  filterTrains(value);
                  setMic(false);
                }}
                handleVoiceOptions={({ label, value }) => {
                  filterTrains(value);
                  setMic(false);
                }}
                handleClose={() => setMic(false)}
                useEnglish={true}
              />
            </div>
          )}
        </React.Fragment>
      }
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={false}
      fullHeight={true}
      showHeaderSeperator={false}
    />
  );
};

export default TrainPicker;
