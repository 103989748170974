import React from "react";
import { useDispatch } from "react-redux";
import { CloseDrawer } from "../../../../../Helpers/EventEmitter";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import { setLang } from "../../../../../Store/Dispatcher/behaviour";
import Drawer from "../../../../../UI/Drawer";
// import { setFaqsForLang } from "../../../../../Store/Actions/faqs";

import Container from "./styles";

const uk =
  "https://sdk.irctc.corover.ai/askdisha-bucket/flag-uk-refactored.png";
const us =
  "https://sdk.irctc.corover.ai/askdisha-bucket/flag-us-refactored.png";
const ind =
  "https://sdk.irctc.corover.ai/askdisha-bucket/flag-india-refactored.png";

const Header = () => {
  const lang = useGetReduxState().behaviour.lang;

  return lang === "en" ? (
    <div>Select Language</div>
  ) : (
    <div>भाषा का चयन करें</div>
  );
};

const Content = () => {
  // const queryLang = useGetReduxState().behaviour.queryLang;
  const lang = useGetReduxState().behaviour.lang;
  const dispatch = useDispatch();

  const handleClick = (lang) => {
    // setQueryLang(lang);

    if (lang === "hi") {
      setLang("hi");
    } else {
      setLang("en");
    }

    // dispatch(setFaqsForLang(lang));
    CloseDrawer();
  };

  return (
    <Container>
      <div className="row">
        <div
          className={`${lang === "en" ? "tab-active" : "tab"}`}
          onClick={() => handleClick("en")}
        >
          <div className="head" style={{}}>
            <h2>Aa</h2>
            <p>English</p>
          </div>
          <div className="flag">
            <p>India</p>
            <img src={ind} alt="" />
          </div>
        </div>
        <div
          className={`${lang === "hi" ? "tab-active" : "tab"}`}
          onClick={() => handleClick("hi")}
        >
          <div className="head" style={{}}>
            <h2>आ</h2>
            <p>हिन्दी</p>
          </div>

          <div className="flag">
            <p>India</p>
            <img src={ind} alt="" />
          </div>
        </div>
      </div>
    </Container>
  );
};

const LanguageMenu = ({ handleClose }) => {
  return (
    <Drawer
      content={<Content />}
      header={<Header />}
      footer={<React.Fragment></React.Fragment>}
      showCross={true}
      showHeaderSeperator={true}
      fullHeight={false}
      handleCloseDrawer={handleClose}
    />
  );
};

export default LanguageMenu;
