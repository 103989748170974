import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 0px 15px;
  padding-top: 6px;

  h4 {
    text-align: center;
    margin-top: 50px;
    font-weight: 500;
    color: gray;
  }
`;

export default Container;
